import {Action, getModule, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import store from '@/store';
import {CanvasSymbol, FigureState, ReferenceSignUpdate, UpdateReferenceSignLabels} from '@/store/models/figure.model';
import {
  CreateApplicationFigureSymbols,
  DeleteApplicationFigureSymbols,
  GetApplicationFigureEdit,
  ScalingUpdate,
  UpdateApplicationFigureScaling,
  UpdateApplicationFigureShowNumber,
  UpdateApplicationFigureSymbols
} from '@/api/services/applicationFigure.api';
import {CreateSymbolEvent, CreateSymbolsEvent, SymbolDeletedVmUpdate, UpdateSymbolEvent} from '@/api/models/figureSymbol.model';
import {
  ApplicationFigureEdit,
  CreateApplicationFigureSymbolsEvent,
  DeleteApplicationFigureSymbolsEvent,
  InsertElementsEvent,
  UpdateApplicationFigureSymbolsEvent
} from '@/api/models/applicationFigure.model';
import {CreateSymbols, DeleteSymbol} from '@/api/services/figureSymbol.api';
import {
  FigureEditorMode,
  FigureSymbol,
  figureSymbolIsReferenceSignMarker,
  FigureSymbolType,
  HelpLine,
  ReferenceSignMarker
} from '../../../../shared/drawingbasemodule/src/api/models/drawingbase.model';
import {removeElement, removeElementByGuid, updateElementByGuid} from '@/util/array.util';
import StoreModelChangeTracker, {getStoreModelChangeTracker} from '@/store/util/StoreModelChangeTracker';
import {InsertSymbol} from '@/api/services/pasteFigureElements.api';
import {CreateHelpLines, UpdateHelpLine} from '@/api/services/helpLine.api';
import {CreateHelpLineEvent, CreateHelpLinesEvent, UpdateHelpLineEvent} from '@/api/models/helpLine.model';
import {CreateReferenceSignMarkers} from '@/api/services/referenceSignMarker.api';
import {
  CreateReferenceSignMarkerEvent,
  CreateReferenceSignMarkersEvent,
  UpdateReferenceSignMarkerEvent
} from '@/api/models/referenceSignMarker.model';


@Module({dynamic: true, namespaced: true, store, name: 'figure'})
class FigureModule extends VuexModule implements FigureState {
  // initial state
  private _isLoading = false;
  private _selectedSymbols: CanvasSymbol[] = [];
  private _applicationFigure: ApplicationFigureEdit | null = null;
  private _figureEditorMode: FigureEditorMode = FigureEditorMode.SELECTION;
  private _showHelpLines = true;

  private static readonly HELP_LINE_PREVIEW_GUID = "dummy-id";

  get isLoading(): boolean {
    return this._isLoading;
  }

  get selectedSymbols(): CanvasSymbol[] {
    return this._selectedSymbols;
  }

  get applicationFigure(): ApplicationFigureEdit | null {
    return this._applicationFigure;
  }

  /**
   * Get FigureSymbol from the symbols layer by GUID.
   * As Vuex doesn't support regular methods we make it a computed property that returns a function.
   */
  get symbol(): (guid: string) => (FigureSymbol | undefined) {
    return (guid: string) => {
      const symbols = this.applicationFigure?.figureSymbols;
      return symbols?.find(it => it.guid === guid);
    };
  }

  get symbols() {
    return this.applicationFigure?.figureSymbols;
  }

  get findAllSymbolsByPredicate(): (predicate: ((symbol: FigureSymbol) => boolean)) => (FigureSymbol[] | undefined) {
    return (predicate: ((symbol: FigureSymbol) => boolean)) => {
      const symbols = this._applicationFigure?.figureSymbols;
      return symbols?.filter(symbol => predicate(symbol));
    }
  }

  get referenceSignMarkersByPredicate(): (predicate: ((symbol: ReferenceSignMarker) => boolean)) => (ReferenceSignMarker[] | undefined) {
    return (predicate: ((symbol: ReferenceSignMarker) => boolean)) => {
      return this.findAllSymbolsByPredicate(figureSymbolIsReferenceSignMarker)?.map(symbol => symbol as ReferenceSignMarker)
        .filter(predicate);
    }
  }

  get findAllReferenceSignMarkersContaining(): (refSignGuid: string) => (ReferenceSignMarker[] | undefined) {
    return (refSignGuid: string) => {
      return this.referenceSignMarkersByPredicate(
        refSignMarker => refSignMarker.referenceSigns.find(referredReferenceSign => referredReferenceSign.guid === refSignGuid)
          !== undefined);
    }
  }

  get symbolTracker(): StoreModelChangeTracker {
    // Get instance from static map because non-primitive fields don't work in Vuex modules!
    return getStoreModelChangeTracker("symbolTracker");
  }

  get helpLinePreviewGuid(): string {
    return FigureModule.HELP_LINE_PREVIEW_GUID;
  }

  get showHelpLines(): boolean {
    return this._showHelpLines;
  }

  get figureEditorMode(): FigureEditorMode {
    return this._figureEditorMode;
  }

  @Mutation
  public setIsLoading(isLoading: boolean): void {
    this._isLoading = isLoading;
  }

  @Mutation
  private setSelectedSymbols(selectedSymbols: CanvasSymbol[]): void {
    this._selectedSymbols = selectedSymbols;
  }

  @Action
  private async setApplicationFigure(applicationFigure: ApplicationFigureEdit | null): Promise<void> {
    await this.symbolTracker.waitForPendingRequestsAndResetState();
    // update changed-date for each symbol
    applicationFigure?.figureSymbols?.forEach(
      it => this.symbolTracker.addEntryChangedDate(it.guid));
    this.setApplicationFigureInternal(applicationFigure);
  }

  @Mutation
  private setApplicationFigureInternal(applicationFigure: ApplicationFigureEdit | null): void {
    this._applicationFigure = applicationFigure;
    this._selectedSymbols = [];
  }

  @Mutation
  private setApplicationFigureScaling(scaling: number): void {
    if (this._applicationFigure) {
      this._applicationFigure.scaling = scaling;
    }
  }

  @Mutation
  public setShowFigureNumber(showFigureNumber: boolean): void {
    if (this._applicationFigure) {
      this._applicationFigure.showFigureNumber = showFigureNumber;
    }
  }

  @Mutation
  private setFigureEditorMode(figureEditorMode: FigureEditorMode): void {
    this._figureEditorMode = figureEditorMode;
  }

  @Mutation
  public toggleShowHelpLines(): void {
    this._showHelpLines = !this._showHelpLines;
  }

  @Mutation
  private renameReferenceSignMarker(update: ReferenceSignUpdate): void {
    const symbols = this._applicationFigure?.figureSymbols;
    return symbols?.filter(symbol => figureSymbolIsReferenceSignMarker(symbol))
      .map(symbol => symbol as ReferenceSignMarker)
      .flatMap(referenceSignMarker => referenceSignMarker.referenceSigns)
      .filter(referenceSign => referenceSign.guid === update.refSignGuid)
      .forEach(referenceSign => {
        referenceSign.assignedLabel = update.newLabel;
        referenceSign.name = update.newName;
      });
  }

  @Mutation
  private deleteReferenceSignFromEachSymbol(refSignGuid: string): void {
    const symbols = this._applicationFigure?.figureSymbols;
    return symbols?.filter(symbol => figureSymbolIsReferenceSignMarker(symbol))
      .map(symbol => symbol as ReferenceSignMarker)
      .forEach(referenceSignMarker => removeElement(referenceSignMarker.referenceSigns,
                                                    (referredReferenceSign => referredReferenceSign.guid === refSignGuid)));
  }

  private get findReferenceSignMarkersWithNoReferredReferenceSign() {
    const symbols = this._applicationFigure?.figureSymbols;
    return symbols?.filter(symbol => figureSymbolIsReferenceSignMarker(symbol))
      .map(symbol => symbol as ReferenceSignMarker)
      .filter(referenceSignMarker => referenceSignMarker.referenceSigns.length === 0)
      .map(referenceSignMarker => referenceSignMarker.guid);
  }

  @Action
  updateReferenceSignLabelsAndNames(change: UpdateReferenceSignLabels): Promise<void> {
    change.changeRefSigns.forEach(update => {
      this.renameReferenceSignMarker(update);
      const modifiedSymbols = this.findAllReferenceSignMarkersContaining(update.refSignGuid);
      modifiedSymbols?.forEach(symbol => this.symbolTracker.updateEntryChangedDate(symbol.guid));
    });

    change.deletedReferenceSignGuids.forEach(refSignGuid => {
      this.deleteReferenceSignFromEachSymbol(refSignGuid);
    })
    const emptyReferenceSignSymbols = this.findReferenceSignMarkersWithNoReferredReferenceSign;
    emptyReferenceSignSymbols?.forEach(symbolGuid => {
      this.removeSymbolFromStoreByGuid(symbolGuid);
      this.symbolTracker.removeEntry(symbolGuid);
    });

    return Promise.resolve();
  }

  @Action
  private setLoadingApplicationFigureStart(): void {
    this.setIsLoading(true);
  }

  @Action
  private setLoadingApplicationFigureEnd(): void {
    this.setIsLoading(false);
  }

  @Action
  updateSelectedSymbols(selectedSymbols: CanvasSymbol[]): void {
    this.setSelectedSymbols(selectedSymbols);
  }

  @Action
  async fetchApplicationFigure(guid: string | null): Promise<ApplicationFigureEdit | null> {
    if (guid === null) {
      this.setApplicationFigureInternal(null);
      return Promise.resolve(null);
    }
    this.setLoadingApplicationFigureStart();
    return GetApplicationFigureEdit(guid)
      .then((applicationFigure: ApplicationFigureEdit) => {
        this.setApplicationFigure(applicationFigure);
        return this._applicationFigure;
      }).finally(() => {
        this.setLoadingApplicationFigureEnd();
      });
  }

  @Action
  async updateScaling(payload: ScalingUpdate): Promise<void> {
    if (payload.guid) {
      return UpdateApplicationFigureScaling(payload)
        .then((figure) => {
          this.setApplicationFigureScaling(figure.scaling);
        });
    }
  }

  @Action
  async updateNumberShown(payload: { guid: string; showFigureNumber: boolean }): Promise<boolean> {
    return UpdateApplicationFigureShowNumber(payload.guid, payload.showFigureNumber)
      .then((figure) => {
        if (this._applicationFigure === null) {
          return false;
        }
        this.setShowFigureNumber(figure.showFigureNumber);
        return this._applicationFigure.showFigureNumber;
      });
  }

  @Action
  addSymbol(event: CreateSymbolEvent) {
    return this.addSymbols([event]);
  }

  @Action
  addSymbols(events: CreateSymbolEvent[]) {
    const symbolsEvent: CreateSymbolsEvent = {
      symbols: events
    }
    const guids = events.map((event) => event.guid);
    return this.symbolTracker
      .wrapCreateRequest(guids, () => CreateSymbols(symbolsEvent), this.addSymbolsToStore);
  }

  @Action
  addHelpLines(events: CreateHelpLineEvent[]) {
    const helpLinesEvent: CreateHelpLinesEvent = {
      helpLines: events
    }
    const guids = events.map((event) => event.guid);
    return this.symbolTracker
      .wrapCreateRequest(guids, () => CreateHelpLines(helpLinesEvent), this.addSymbolsToStore);
  }

  @Action
  addReferenceSignMarker(events: CreateReferenceSignMarkerEvent[]) {
    const referenceSignMarkersEvent: CreateReferenceSignMarkersEvent = {
      referenceSignMarkers: events
    }
    const guids = events.map((event) => event.guid);
    return this.symbolTracker
      .wrapCreateRequest(guids, () => CreateReferenceSignMarkers(referenceSignMarkersEvent), this.addSymbolsToStore);
  }

  @Mutation
  private addSymbolsToStore(symbols: FigureSymbol[]) {
    symbols.forEach((symbol) => this._applicationFigure?.figureSymbols.push(symbol));
  }

  @Action
  updateSymbol(event: UpdateSymbolEvent) {
    const updateApplicationFigureSymbolsEvent: UpdateApplicationFigureSymbolsEvent = {
      helpLines: [],
      referenceSignMarkers: [],
      symbols: [event]
    };
    return this.updateFigureSymbols(updateApplicationFigureSymbolsEvent);
  }

  @Action
  updateHelpLine(event: UpdateHelpLineEvent) {
    return this.symbolTracker.wrapUpdateRequest(event.guid, () => UpdateHelpLine(event), this.updateSymbolInStore);
  }

  @Action
  updateReferenceSignMarker(event: UpdateReferenceSignMarkerEvent) {
    const updateApplicationFigureSymbolsEvent: UpdateApplicationFigureSymbolsEvent = {
      helpLines: [],
      referenceSignMarkers: [event],
      symbols: []
    };
    return this.updateFigureSymbols(updateApplicationFigureSymbolsEvent);
  }

  @Mutation
  private updateSymbolInStore(symbol: FigureSymbol) {
    updateElementByGuid(this._applicationFigure?.figureSymbols, symbol);
  }

  @Mutation
  public updateSymbolsInStore(symbols: FigureSymbol[]) {
    symbols.forEach((symbol) => updateElementByGuid(this._applicationFigure?.figureSymbols, symbol));
  }

  @Action
  removeSymbol(guid: string) {
    return this.symbolTracker.wrapDeleteRequest(guid, () => DeleteSymbol(guid), this.removeSymbolFromStore);
  }

  @Mutation
  private removeSymbolsFromStoreByGuid(guids: string[]) {
    guids.forEach((guid) => {
      const removedSymbol = removeElementByGuid(this._applicationFigure?.figureSymbols, guid);
      if (removedSymbol) {
        const symbolType = removedSymbol.symbolType;
        switch (symbolType) {
          case FigureSymbolType.ARROW:                    // nothing to do
          case FigureSymbolType.BRACE:                    // nothing to do
          case FigureSymbolType.CURVE:                    // nothing to do
            break;
          case FigureSymbolType.HELP_LINE: {
            const removedHelpline = removedSymbol as HelpLine;
            const symbols = this._applicationFigure?.figureSymbols;

            if (symbols) {
              // remove horizontal docked reference signs
              symbols.filter(symbol => figureSymbolIsReferenceSignMarker(symbol))
                .map(symbol => symbol as ReferenceSignMarker)
                .filter(marker => marker.horizontal?.helpLineGuid === removedHelpline.guid)
                .forEach(value => value.horizontal = null);

              // remove vertical docked reference signs
              symbols.filter(symbol => figureSymbolIsReferenceSignMarker(symbol))
                .map(symbol => symbol as ReferenceSignMarker)
                .filter(marker => marker.vertical?.helpLineGuid === removedHelpline.guid)
                .forEach(value => value.vertical = null);
            }
          }
            break;
          case FigureSymbolType.LINE:                     // nothing to do
          case FigureSymbolType.REFERENCE_SIGN_MARKER:    // nothing to do
            break;
          default: {
            const exhaustiveCheck: never = symbolType;
            throw new Error(`Unhandled symbol type: ${exhaustiveCheck}`);
          }
        }
      }
    });
  }

  @Mutation
  private removeSymbolFromStore(update: SymbolDeletedVmUpdate) {
    return removeElementByGuid(this._applicationFigure?.figureSymbols, update.guid);
  }

  @Mutation
  private removeSymbolFromStoreByGuid(guid: string) {
    removeElementByGuid(this._applicationFigure?.figureSymbols, guid);
  }

  @Action({rawError: true})
  insertElements(elements: InsertElementsEvent) {
    return InsertSymbol(elements).then((res) => {
      const symbols = [res.helpLines, res.referenceSignMarkers, res.symbols]
        .filter(it => it && it.length > 0)
        .flatMap(it => it);

      if (symbols.length > 0) {
        symbols.forEach((symbol) => {
          this.symbolTracker.addEntryChangedDate(symbol.guid);
        });
        this.addSymbolsToStore(symbols);
      }
      return res;
    });
  }

  @Action
  createFigureSymbols(event: CreateApplicationFigureSymbolsEvent) {
    const figureGuid = this._applicationFigure?.guid as string;

    return CreateApplicationFigureSymbols(figureGuid, event)
      .then((res) => {
        const symbols = [res.helpLines, res.referenceSignMarkers, res.symbols]
          .filter(it => it && it.length > 0)
          .flatMap(it => it);

        if (symbols.length > 0) {
          symbols.forEach((symbol) => {
            this.symbolTracker.addEntryChangedDate(symbol.guid);
          });
          this.addSymbolsToStore(symbols);
        }
        return res;
      });
  }

  @Action
  updateFigureSymbols(event: UpdateApplicationFigureSymbolsEvent) {
    const figureGuid = this._applicationFigure?.guid as string;

    return UpdateApplicationFigureSymbols(figureGuid, event)
      .then((res) => {
        const symbols = [res.helpLines, res.referenceSignMarkers, res.symbols]
          .filter(it => it && it.length > 0)
          .flatMap(it => it)

        if (symbols.length > 0) {
          this.updateSymbolsInStore(symbols);
          symbols.forEach((symbol) => {
            this.symbolTracker.addEntryChangedDate(symbol.guid);
          });
        }
        return res;
      });
  }

  @Action
  deleteFigureSymbols(event: DeleteApplicationFigureSymbolsEvent) {
    const figureGuid = this._applicationFigure?.guid as string;

    return DeleteApplicationFigureSymbols(figureGuid, event)
      .then((res) => {
        const symbolGuids = [res.helpLineGuids, res.referenceSignMarkerGuids, res.symbolGuids]
          .filter(it => it && it.length > 0)
          .flatMap(it => it);

        if (symbolGuids.length > 0) {
          this.removeSymbolsFromStoreByGuid(symbolGuids);
          symbolGuids.forEach((guid) => {
            this.symbolTracker.removeEntry(guid);
          });
        }
        return symbolGuids;
      });
  }

  @Action
  activateSolidCurveMode(): void {
    this.setFigureEditorMode(FigureEditorMode.SOLID_CURVE);
  }

  @Action
  activateDashedCurveMode(): void {
    this.setFigureEditorMode(FigureEditorMode.DASHED_CURVE);
  }

  @Action
  activateSelectionMode(): void {
    this.setFigureEditorMode(FigureEditorMode.SELECTION);
  }

  @Action
  activateZoomSelectionMode(): void {
    this.setFigureEditorMode(FigureEditorMode.ZOOM_SELECTION);
  }
}

export default getModule(FigureModule);
