<template>
  <transition name="fade">
    <div class="modal-dialog" v-if="(show == null && displayOnInit) || show" @keydown="handleTabEvent" ref="modalContainer"
         @mousedown.stop="onMouseDown($event)">
      <div class="modal-dialog-backdrop" @click="closeModalOnBackdropClick"/>

      <div tabindex="0" class="modal-dialog-dialog" :style="getStyling()">
        <div class="modal-dialog-header" v-if="showHeader">
          <slot name="header"/>
          <button v-if="showClose"
                  type="button"
                  class="modal-dialog-close icon-button"
                  @click="closeModal"
                  :title="$t('general.close')">
            <i class="exi exi-close"/>
          </button>
        </div>

        <div class="modal-dialog-body">
          <slot name="body"/>
        </div>

        <div class="modal-dialog-footer" v-if="showFooter">
          <slot name="footer"/>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import {Component, Prop, Ref, toNative, Vue} from 'vue-facing-decorator';

@Component
class ModalDialog extends Vue {
  @Prop({default: false}) private displayOnInit!: boolean;
  @Prop({default: true}) private showHeader!: boolean;
  @Prop({default: true}) private showFooter!: boolean;
  @Prop({default: true}) private showClose!: boolean;
  @Prop({default: true}) private dataLoaded!: boolean;
  @Prop({default: undefined}) private onClose!: () => void;
  @Prop({default: undefined}) private width!: number;
  @Prop({default: undefined}) private margin!: string | undefined;
  @Ref('modalContainer') private modalContainer!: HTMLElement;

  private onMouseDown(event: MouseEvent): void {
    // Do nothing - propagation is stopped by vue.js handler above
  }

  private focusableElementsSelectors = 'button, [href],input:not(:disabled):not([readonly]):not([type=hidden]) , select, textarea, [tabindex]:not([tabindex="-1"])';

  // We need a ternary value to differ if true or false already was set explicitly (via toggleModal or mounted).
  // As long as it wasn't set we have to check displayOnInit to be able to display the dialog immediately before mounted was called.
  private show: null | boolean = null;

  toggleModal(showDialog: boolean): void {
    this.show = showDialog;
    if (document) {
      if (showDialog) {
        this.setFocusAsSoonAsAvailable(100, 5000);
      }
      const body = document.querySelector('body');
      if (body && body.classList) {
        if (showDialog) {
          body.classList.add('overflow-hidden');
        } else {
          if (this.onClose) {
            this.onClose();
          }
          document.removeEventListener('keydown', this.keyDownEventListener);
          body.classList.remove('overflow-hidden');
        }
      }
    }
  }

  closeModalOnBackdropClick() {
    if (this.showClose) {
      this.toggleModal(false);
    }
  }

  closeModal() {
    this.toggleModal(false);
  }

  openModal() {
    document.addEventListener('keydown', this.keyDownEventListener);
    this.toggleModal(true);
  }

  getStyling(): { width?: string; margin?: string } {
    const styling: { width?: string; margin?: string } = {};

    if (this.width) {
      styling.width = this.width + 'px';
    }
    if (this.margin) {
      styling.margin = this.margin;
    }
    return styling;
  }

  mounted() {
    this.show = this.displayOnInit;
    if (this.show) {
      this.toggleModal(this.show);
    }
  }

  /**
   * keyDownEvent listener for the modal dialog to prevent the default keyboard events.
   * This listener is added when the data of the modal dialog is not yet loaded.
   * It is removed once the data is loaded and focusable elements in the modal dialog are defined or when the modal dialogue is closed.
   * @param event: KeyBoardEvent
   */
  private keyDownEventListener = (event: KeyboardEvent): void => {
    event.preventDefault();
  }

  /**
   * Tends to set the focus on the modal and prevent the tab event from propagating to other components other than the modal dialog.
   * During a particular period  of time (@param maxTime),every while (@param wait) checks if the modal content is loaded.
   * Once the elements are in the modal, the focus is set on the first element.
   * @param wait: time to pause and wait before cheking if the modal content is loaded.
   * @param maxTime: the maximum period before stop checking if the modal content has benn loaded..
   */
  private setFocusAsSoonAsAvailable(wait: number, maxTime: number): void {
    // Base case, give up after a while
    if (maxTime <= 0) {
      return;
    }

    setTimeout(() => {
      if (!this.show) {
        return;
      }
      this.modalContainer.focus();

      const focusableList = this.getFocusableList(this.modalContainer);
      if (focusableList.length && this.dataLoaded) {
        (focusableList[0] as HTMLElement).focus();
        document.removeEventListener('keydown', this.keyDownEventListener);
      } else {
        this.setFocusAsSoonAsAvailable(wait, maxTime - wait);
      }
    }, wait);
  }

  /**
   * Handles the TabEvent in order to be applied only on the modal dialog when it is open and prevent this events on the background of
   * the modal.
   * @param event
   */
  handleTabEvent(event: KeyboardEvent): void {
    if (event.key !== 'Tab') {
      return;
    }
    const focusableList = this.getFocusableList(this.$refs.modalContainer as HTMLElement);
    if (focusableList.length === 0) {
      return;
    }
    const last = focusableList.length - 1;
    if (event.shiftKey === false && event.target === focusableList[last]) {
      // if the tab is pressed without the shiftKey and the focus is on the last element on the modal,
      // then set the focus on the first element on the modal.
      event.preventDefault();
      (focusableList[0] as HTMLElement).focus();
    } else if (event.shiftKey === true && event.target === focusableList[0]) {
      // if the tab and the shiftKey are pressed together and the focus is on the first element on the modal,
      // then set the focus on the last element on the modal.
      event.preventDefault();
      (focusableList[last] as HTMLElement).focus();
    }
  }

  /**
   * Get the focusable elements in the modal dialogue.
   * @param element: the modal dialogue
   */
  private getFocusableList(element: HTMLElement): NodeListOf<Element> {
    return element.querySelectorAll(this.focusableElementsSelectors);
  }
}

export default toNative(ModalDialog);
export {ModalDialog};
</script>

<style scoped lang="scss">
@import '../../assets/styles/constants.scss';

.modal-dialog {
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  //has to be higher up than the header
  z-index: 50;
  align-items: center;
  display: flex;
  justify-content: center;

  &-backdrop {
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  &-dialog {
    background-color: #ffffff;
    position: relative;
    width: 600px;
    font-size: $font-size-normal;
    display: flex;
    flex-direction: column;
    border-radius: $dialog-border-radius;
    z-index: 2;
    @media screen and (max-width: 992px) {
      width: 90%;
    }
  }

  &-close {
    width: 30px;
    height: 30px;
    padding-right: 6px;
    padding-left: 6px;
  }

  &-header {
    padding: 20px 20px 10px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .modal-dialog-close {
      margin-top: -12px;
      margin-right: -12px;

      .exi { // eXXcellent Icons
        height: 12px;
        width: 12px;
      }
    }
  }

  &-body {
    padding: 10px 20px 10px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  &-footer {
    padding: 10px 20px 20px;
  }

}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>

<style lang="scss">
@import '../../assets/styles/constants.scss';

:not(.with-pinned-header) {
  .modal-dialog {
    top: $header-height;
  }
}

.with-pinned-header {
  .modal-dialog {
    top: calc(#{$header-height} + #{$header-bar-nav-buttons-height});
  }
}

// Hide outlines in Safari
.modal-dialog {
  &-dialog {
    outline: none;
  }
}
</style>
