<template>
  <div class="document-structure-tree">
    <div class="header">
      <h2>{{ $t('textBlocks') }}</h2>
    </div>
    <Tree ref="document-structure-tree-container" class="tree" @onClick="handleClick" :node="root"></Tree>
  </div>
</template>

<script lang="ts">
import {Component, Ref, toNative, Vue, Watch} from 'vue-facing-decorator';
import EditorModule from '../store/modules/EditorModule';
import {AbstractBlockViewModel, SemanticType, StructuralBlockViewModel, TextBlockViewModel} from '@/api/models/editor.model';
import {DocumentStructureNode} from './documentStructureTree/DocumentStructureNode';
import Tree from '@/components/documentStructureTree/Tree.vue';
import CoverSheetDialog from "@/views/CoverSheetDialog.vue";
import {Mode} from '@/util/enums';
import {nextTick} from 'vue';


@Component(
  {
    name: 'DocumentStructureTree',
    components: {Tree, CoverSheetDialog},
  })
class DocumentStructureTree extends Vue {

  private root: DocumentStructureNode = new DocumentStructureNode();
  private nodeByGuid: Map<string, DocumentStructureNode> = new Map();
  @Ref('document-structure-tree-container') private documentStructureTreeContainer!: HTMLElement;

  private handleClick(node: DocumentStructureNode): void {
    this.clearActiveNodes();
    this.setActiveNode(node);
    EditorModule.selectGuidForEditor(node.guid);
  }

  get selectionGuidForDocumentStructureTree(): string | null {
    return EditorModule.selectionGuidForDocumentStructureTree;
  }

  @Watch("selectionGuidForDocumentStructureTree", {immediate: true})
  private scrollAndSelectTo(guid: string | null): void {
    const node = guid ? this.nodeByGuid.get(guid) : null;

    // Abort method when node was already selected
    if (node && node.active) {
      return;
    }

    this.clearActiveNodes();

    // Selection
    if (node) {
      this.setActiveNode(node);
    }

    // Scroll
    nextTick(() => {
      const treeNode = document.getElementById("document-structure-tree-" + guid);
      if (!treeNode) {
        return;
      }
      const headerOffset = 62;
      const subheaderOffset = 50;
      // eslint-disable-next-line
      const treeContainerHeight = ((this.documentStructureTreeContainer as any).$el as HTMLElement).getBoundingClientRect().height;
      if (!treeContainerHeight) {
        return;
      }
      const treeNodeHeight = treeNode.getBoundingClientRect().height;
      let treeNodeOffsetCenter = treeNode.offsetTop - (treeContainerHeight - treeNodeHeight) / 2 - headerOffset - subheaderOffset;
      treeNodeOffsetCenter *= EditorModule.zoomLevel; // scale offset

      // eslint-disable-next-line
      ((this.documentStructureTreeContainer as any).$el as HTMLElement).scrollTo({top: treeNodeOffsetCenter, behavior: 'smooth'});
    });
  }

  private clearActiveNodes() {
    this.nodeByGuid.forEach((node) => {
      node.active = false;
    })
  }

  get reload(): string {
    return EditorModule.reloadCount + "#" + EditorModule.updateCount + "#" + EditorModule.reviewCount;
  }

  get Mode() {
    return Mode;
  }

  @Watch("reload", {immediate: true})
  private onDocumentChange(): void {

    this.root = this.createDocumentStructureNodes(EditorModule.currentRootBlock)[0];
    this.root.visible = false; // ignore root node in tree visualization
    this.root.expanded = true;

    this.updateNodeByGuid();
    this.scrollAndSelectTo(EditorModule.selectionGuidForDocumentStructureTree);
  }

  setActiveNode(node: DocumentStructureNode): void {

    let currentNode: DocumentStructureNode | null = node;
    let visibleNodeFound = false;

    // Expand node and all ancestors
    while (currentNode !== null) {
      // Set first visible node to active
      if (currentNode.visible && !visibleNodeFound) {
        currentNode.active = true;
        visibleNodeFound = true;
      }
      currentNode.expanded = true;
      currentNode = currentNode.parentNode;
    }
  }

  private updateNodeByGuid(): void {
    this.nodeByGuid.clear();
    this.createNodeByGuid(this.root);
  }

  private createNodeByGuid(node: DocumentStructureNode): void {
    this.nodeByGuid.set(node.guid, node);
    node.children.forEach((childNode) => {
      this.createNodeByGuid(childNode);
    })
  }

  private createDocumentStructureNodes(block: AbstractBlockViewModel | null): DocumentStructureNode[] {
    if (block === null) {
      return [new DocumentStructureNode()];
    }

    const childNodes = (Object.getOwnPropertyNames(block).includes('children')) ?
      (block as StructuralBlockViewModel).children.map((blockNode) => this.createDocumentStructureNodes(blockNode)).flat() : [];

    if (block.logicalBlock) {
      const oldNode = this.nodeByGuid.get(block.guid);
      const parentNode = new DocumentStructureNode(block, oldNode);

      parentNode.isEmpty = (this.childrenContainText(block as StructuralBlockViewModel) === false);

      childNodes.forEach((childNode) => {
        childNode.parentNode = parentNode;
        parentNode.addChildNode(childNode);
      });

      return [parentNode];
    }

    return childNodes;
  }

  private childrenContainText(block: StructuralBlockViewModel): boolean | null {
    if (block.semanticType === SemanticType.COVER_SHEET) {
      return true;
    }

    if (block.children.length === 0) {
      return false;
    }

    let childrenContainsText: boolean | null = null;
    block.children.forEach(child => {
      const childContainsText = this.containsText(child);
      if (childContainsText === true) {
        childrenContainsText = true;
      } else if (childContainsText === false && childrenContainsText === null) {
        childrenContainsText = false;
      }
    });
    return childrenContainsText;
  }

  private containsText(block: AbstractBlockViewModel): boolean | null {
    if (block.logicalBlock) {
      return null;
    }

    if (Object.getOwnPropertyNames(block).includes('richText')) {
      return (block as TextBlockViewModel).richText.trim().length > 0;
    }

    return this.childrenContainText(block as StructuralBlockViewModel);
  }
}

export default toNative(DocumentStructureTree);
</script>


<style lang="scss">
@import '../assets/styles/colors.scss';
@import '../assets/styles/constants.scss';

.document-structure-tree {
  .edit-coversheet-icon {
    .icon-button {
      padding-left: 0px;
    }
  }
}

</style>

<style lang="scss" scoped>
@import '../assets/styles/colors.scss';
@import '../assets/styles/constants.scss';

.document-structure-tree {
  width: inherit;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;

  .header {
    width: inherit;
    min-height: $subheader-height;
    height: $subheader-height;
    padding-left: 5px; // To be in line with the tree

    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    -webkit-user-select: none;
    user-select: none;
    border-bottom: 1px solid $pengine-grey;

    h2 {
      margin-top: 0;
      padding-left: 9px;
      margin-bottom: 0;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }

    .edit-coversheet-icon {
      margin-top: 0;
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  .tree {
    width: inherit;
    height: 100%;
    padding-top: 6px;
    padding-left: 9px;
    overflow-y: auto;
    overflow-x: auto;
  }
}

</style>
