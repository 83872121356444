import StoreModelChangeTracker from '@/store/util/StoreModelChangeTracker';

type LockInfo = {
  numLocks: number;
  pendingForRemoval: boolean;
}

export type GuidConsumer = (guid: string) => void;

/**
 * This util class tracks changes for objects inside a view.
 * The objects are identified by string GUID.
 * Together with StoreModelChangeTracker a diff can be calculated between store and view versions of objects.
 */
class ViewModelChangeTracker {

  private readonly _changedDateMap: Map<string, Date> = new Map();
  private readonly _lockedMap: Map<string, LockInfo> = new Map();

  reset(){
    this._changedDateMap.clear()
    this._lockedMap.clear();
  }

  addEntry(guid: string, ts?: Date) {
    const lockInfo = this._lockedMap.get(guid);
    if (lockInfo !== undefined) {
      throw new Error(`Object with with guid ${guid} is already registered.`);
    }
    this.setEntryChangedDate(guid, ts);
    this._lockedMap.set(guid, {numLocks: 0, pendingForRemoval: false});
  }

  /**
   * Set changed-date for guid to new timestamp (default: now).
   * Note: This should be called manually for initial view and whenever an item is added/updated to the view.
   *
   * @param guid
   * @param ts
   */
  private setEntryChangedDate(guid: string, ts?: Date) {
    this._changedDateMap.set(guid, ts || new Date());
  }

  /**
   * Lock this guid, i.e. don't consider it in a diff.
   * Note: This should be called manually when a view item should not be synced from store, e.g. because the user currently drags it.
   *
   * @param guid
   */
  lockEntry(guid: string) {
    const lockInfo = this._lockedMap.get(guid);
    if (lockInfo  === undefined) {
      throw new Error(`Cannot lock entry with guid ${guid}. The object is not registered.`);
    }
    const updated = {
      ...lockInfo,
      numLocks: lockInfo.numLocks + 1
    };
    this._lockedMap.set(guid, updated);
  }

  /**
   * Unlock this guid.
   * Note: This should be called manually sometime after #lockEntry().
   *
   * @param guid
   */
  unlockEntry(guid: string) {
    const lockInfo = this._lockedMap.get(guid);
    if (lockInfo  === undefined) {
      throw new Error(`Cannot unlock entry with guid ${guid}. The object is not registered.`);
    }
    if (lockInfo.numLocks === 0 && !lockInfo.pendingForRemoval) {
      throw new Error(`Cannot unlock entry with guid ${guid}. The object is not locked and was not removed yet.`);
    }
    const updated = {
      ...lockInfo,
      numLocks: lockInfo.numLocks - 1,
    };

    if (updated.numLocks <= 0 && updated.pendingForRemoval) {
      this._changedDateMap.delete(guid);
      this._lockedMap.delete(guid);
      return true;
    }
    this._lockedMap.set(guid, updated);
    return false;
  }

  /**
   * Remove changed-date for guid.
   * Note: This should be called manually whenever an item is removed from the view.
   *
   * @param guid
   */
  removeEntry(guid: string) {

    const lockInfo = this._lockedMap.get(guid);
    if (lockInfo  === undefined) {
      throw new Error(`Cannot remove entry with guid ${guid}. The object is not present.`);
    }

    if (lockInfo.numLocks === 0) {
      this._changedDateMap.delete(guid);
      this._lockedMap.delete(guid);
      return true;
    } else {
      this._lockedMap.set(guid, {...lockInfo, pendingForRemoval: true});
      return false;
    }
  }

  /**
   * Get all guids that exist as keys in changed-dates map.
   */
  private getAllGuids(): string[] {
    return Array.from(this._changedDateMap.keys());
  }

  /**
   * Iterates over all active guids, i.e. elements for which removal is not pending
   * @param consumer a consumer function receiving the guids, on after another.
   */
  forEachActiveGuid(consumer: GuidConsumer): void {
    for (const [guid, lockInfo] of this._lockedMap) {
      if (!lockInfo.pendingForRemoval) {
        consumer(guid);
      }
    }
  }

  /**
   * Get changed-date for guid.
   *
   * @param guid
   */
  getChangedDate(guid: string): Date | undefined {
    return this._changedDateMap.get(guid);
  }

  /**
   * Check if guid is locked.
   *
   * @param guid
   */
  isLocked(guid: string, checkRegistered: boolean = false): boolean {
    const lockInfo = this._lockedMap.get(guid);
    if (lockInfo === undefined) {
      if (checkRegistered) {
        throw new Error(`Cannot check lock state of entry with guid ${guid}. The object is not registered.`);
      }
      return false;
    }

    return lockInfo.numLocks > 0;
  }

  /**
   * Calculate diff between this view change tracker and given store change tracker.
   *
   * All guids that exist either in the store or in the view *and* which are not currently locked (either in store or view)
   * are classified in one of the three groups 'added', 'updated' and 'removed':
   * - If guid exists only in the store it is classified as added.
   * - If guid exists only in the view it is classified as removed.
   * - If it exists in both and the changed-date in the store is newer than in the view it is classified as updated.
   * Additionally if there are guids that are added, updated or removed, the changed flag returns true.
   *
   * @param storeModelChangeTracker
   * @returns GuidModelDiff - guids that should be added, updated and removed in *view* compared to *store* versions.
   */
  calcDiff(storeModelChangeTracker: StoreModelChangeTracker, failIfGuidsNotRegistered: boolean): GuidModelDiff {
    const added: string[] = [];
    const updated: string[] = [];
    const removed: string[] = [];

    const storeModelGuids = storeModelChangeTracker.getAllGuids();
    const viewModelGuids = this.getAllGuids();

    storeModelGuids.forEach(guid => {
      const locked = storeModelChangeTracker.isLocked(guid) || this.isLocked(guid, failIfGuidsNotRegistered);
      if (!locked) {
        const viewEntryExists = viewModelGuids.includes(guid);
        if (!viewEntryExists) {
          added.push(guid);
        } else if (this.isNewer(storeModelChangeTracker.getChangedDate(guid), this.getChangedDate(guid))) {
          updated.push(guid);
        }
      }
    });

    viewModelGuids.forEach(guid => {
      const locked = storeModelChangeTracker.isLocked(guid) || this.isLocked(guid, failIfGuidsNotRegistered);
      if (!locked) {
        const storeEntryExists = storeModelGuids.includes(guid);
        if (!storeEntryExists) {
          removed.push(guid);
        }
      }
    });

    const changed: boolean = added.length > 0 || updated.length > 0 || removed.length > 0;
    return {changed: changed, addedGuids: added, updatedGuids: updated, removedGuids: removed};
  }

  /**
   * Check if ts1 is newer than ts2.
   * - If ts1 is undefined: false
   * - If ts2 is undefined: true
   * - If ts1 > ts2: true
   * @private
   */
  private isNewer(ts1: Date | undefined, ts2: Date | undefined): boolean {
    if (!ts1) {
      return false;
    }
    if (!ts2) {
      return true;
    }
    return ts1.getTime() > ts2.getTime();
  }
}

export interface GuidModelDiff {
  // true if there are added, updated or removed guids
  changed: boolean;
  // Guids that exist only in the store
  addedGuids: string[];
  // Guids for which changed-date in the store is newer than in the view
  updatedGuids: string[];
  // Guids that exist only in the view
  removedGuids: string[];
}

export default ViewModelChangeTracker;
