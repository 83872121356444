import {
  BlockDescriptor,
  CommandLocalizedMessage,
  MultipleBlockDescriptor,
  ReferenceBlockDescriptor,
  SingleBlockDescriptor,
  WithinBlockDescriptor
} from '@/api/models/editor.model';
import {TranslateResult} from 'vue-i18n';
import ApplicationModule from '@/store/modules/ApplicationModule';
import i18n from '@/i18n';

export class UndoRedoAction {
  constructor(actionName: string, id: number, guid?: string) {
    this._actionName = actionName;
    this._id = id;
    this._guid = guid;
  }

  private readonly _actionName: string;
  private readonly _id: number;
  private readonly _guid: string | undefined;


  get actionName(): string {
    return this._actionName;
  }

  get id(): number {
    return this._id;
  }

  get guid(): string | undefined {
    return this._guid;
  }
}

/**
 * Translates the given block descriptor according to it's type.
 * @param blockDescriptor the descriptor to translate
 */
function translateBlockDescriptor(blockDescriptor: BlockDescriptor): TranslateResult {
  const type = blockDescriptor.type;
  switch (type) {
    case 'Single': {
      const singleBlockDescriptor: SingleBlockDescriptor = blockDescriptor as SingleBlockDescriptor;

      if (singleBlockDescriptor.additionalValue) {
        //map mit additional value als Wert und claimNo als key erstellen und reoplace
        const properties = {claimNo: singleBlockDescriptor.additionalValue};
        return ApplicationModule.templateText(singleBlockDescriptor.semanticType + '.name', properties);
      }
      let translatedText = ApplicationModule.templateText(singleBlockDescriptor.semanticType + '.name');

      //if claimNo could not be set, then replace it with this default text
      translatedText = translatedText.replace("{claimNo}", "(gelöschter Anspruch)");
      return translatedText;

    }
    case 'Within': {
      const withinBlockDescriptor: WithinBlockDescriptor = blockDescriptor as WithinBlockDescriptor;
      const translatedValues = [
        translateBlockDescriptor(withinBlockDescriptor.child),
        translateBlockDescriptor(withinBlockDescriptor.parent)
      ];
      return i18n.global.t('command.description.phrase.Within', translatedValues);
    }
    case 'Reference': {
      const referenceBlockDescriptor: ReferenceBlockDescriptor = blockDescriptor as ReferenceBlockDescriptor;
      const translatedValues = [
        translateBlockDescriptor(referenceBlockDescriptor.referer),
        translateBlockDescriptor(referenceBlockDescriptor.reference)
      ];
      return i18n.global.t('command.description.phrase.Refering', translatedValues);
    }
    case 'Multiple': {
      const blockDescriptorList = (blockDescriptor as MultipleBlockDescriptor).list;
        const translatedHead = blockDescriptorList.slice(0, blockDescriptorList.length - 1)
          .map(subDescriptor => translateBlockDescriptor(subDescriptor))
          .join(i18n.global.t('command.description.delimiter.Listing').toString());
        const translatedTail = translateBlockDescriptor(blockDescriptorList[blockDescriptorList.length - 1]);
        return i18n.global.t('command.description.delimiter.Join', [translatedHead, translatedTail]);
    }
    default: {
      const exhaustiveCheck: never = type;
      throw new Error(`Unhandled BlockDescriptor: ${exhaustiveCheck}`);
    }
  }
}

export function translateBlockDescriptorForAutofillButtonAiTemplates(blockDescriptor: BlockDescriptor): TranslateResult {
  const type = blockDescriptor.type;
  switch (type) {
    case 'Single': {
      const singleBlockDescriptor: SingleBlockDescriptor = blockDescriptor as SingleBlockDescriptor;
      let translatedText =  ApplicationModule.templateTextByKey(singleBlockDescriptor.semanticType + '.name');
      translatedText = translatedText.replace(" {claimNo}", "");
      return translatedText
    }
    case 'Within': {
      const withinBlockDescriptor: WithinBlockDescriptor = blockDescriptor as WithinBlockDescriptor;
      if((withinBlockDescriptor.child.semanticType as string).includes((withinBlockDescriptor.parent.semanticType as string))) {
        const translatedValue =
          translateBlockDescriptorForAutofillButtonAiTemplates(withinBlockDescriptor.child);
        return translatedValue;
      } else {
        const translatedValues = [
          translateBlockDescriptorForAutofillButtonAiTemplates(withinBlockDescriptor.child),
          translateBlockDescriptorForAutofillButtonAiTemplates(withinBlockDescriptor.parent)
        ];
        return i18n.global.t('autofillButtonDropdown.phrase.Within', translatedValues);
      }
    }
    case 'Multiple':
    case 'Reference':
      throw new Error("Invalid arguments");
    default: {
      const exhaustiveCheck: never = type;
      throw new Error(`Unhandled BlockDescriptor: ${exhaustiveCheck}`);
    }
  }
}

/**
 * Translates a command's localized message containing block descriptors.
 * @param message the command's localized message
 */
export function translateBlockLocalizedMessage(message: CommandLocalizedMessage): string {
  const translatedValues = message.values.map(value => translateBlockDescriptor(value));
  return i18n.global.t('command.description.action.' + message.key, translatedValues).toString();
}
