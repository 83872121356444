import {DnDEvent} from 'vue-easy-dnd';
import {ReferenceSignListEntry} from '@/store/models/referenceSign.model';
import paper from 'paper';
import {ApplicationFigureEdit} from '@/api/models/applicationFigure.model';
import {BraceType, FigureSymbolType, Orientation} from '../../../../shared/drawingbasemodule/src/api/models/drawingbase.model';
import {ReferenceSignMarkerPrototype} from '@/api/models/referenceSign.model';

export type CanvasSymbol = paper.Item;

export interface FigureState {
  isLoading: boolean;
  applicationFigure: ApplicationFigureEdit | null;
  selectedSymbols: CanvasSymbol[];
}

export interface FigureEditorDndEvent extends DnDEvent {
  data: ReferenceSignListEntry | SymbolsListEntry | HelpLineListEntry;
}

export interface SymbolsListEntry {
  name: string;
  type: FigureSymbolType;
  dashed: boolean;
  braceType: BraceType;
}

export interface HelpLineListEntry {
  name: string;
  type: FigureSymbolType;
  orientation: Orientation;
}

export type HitResult = {
  type: string; // The type of hit (e.g., 'fill', 'stroke', 'segment', etc.)
  item: paper.Item; // The item that was hit
  location?: paper.CurveLocation; // The location on the curve, if applicable
  segment?: paper.Segment; // The segment that was hit, if applicable
  point?: paper.Point; // The point of the hit
  bounds?: paper.Rectangle; // The bounds that were hit, if applicable
};

export type HitTestOptions = {
  tolerance?: number; // The tolerance of the hit-test
  class?: new (...args: any[]) => any; // Constructor function for filtering item classes
  match?: (hitResult: HitResult) => boolean; // Match function to decide if the hit result should be returned
  fill?: boolean; // Hit-test the fill of items (default: true)
  stroke?: boolean; // Hit-test the stroke of path items (default: true)
  segments?: boolean; // Hit-test for segment points of Path items (default: true)
  curves?: boolean; // Hit-test the curves of path items
  handles?: boolean; // Hit-test for the handles of path segments
  ends?: boolean; // Only hit-test for the first or last segment points of open path items
  position?: boolean; // Hit-test the item.position of items
  center?: boolean; // Hit-test the center of the bounding rectangle of items
  bounds?: boolean; // Hit-test the corners and side-centers of the bounding rectangle of items
  guides?: boolean; // Hit-test items that have Item#guide set to true
  selected?: boolean; // Only hit selected items
};

export const CANVAS_HIT_OPTIONS: HitTestOptions = {
  segments: true,
  stroke: true,
  fill: true,
  bounds: true,
  handles: true,
  tolerance: 10
};

export const SEGMENT_HIT_OPTIONS: HitTestOptions = {
  segments: true,
  stroke: true,
  fill: true,
  tolerance: 25
};

export const CURVE_HANDLE_HIT_OPTIONS: HitTestOptions = {
  segments: false,
  handles: true,
  stroke: false,
  fill: false,
  tolerance: 50
};

export const SELECTION_RECTANGLE_STYLE = {
  strokeColor: "black",
  dashArray: [4, 6]
}

export interface DraggableSymbolEntry {
  name: string;
  label: string;
  edit: boolean;
  type: FigureSymbolType;
  braceType?: BraceType;
  dashed?: boolean;
}

export interface SymbolData {
  applicationGuid: string;
  figureGuid: string;
  copiedData: string[];
  referenceSigns: ReferenceSignMarkerPrototype[];
}

export interface ReferenceSignUpdate {
  newLabel: string;
  newName: string;
  refSignGuid: string;
}

export interface UpdateReferenceSignLabels {
  changeRefSigns: ReferenceSignUpdate[];
  deletedReferenceSignGuids: string[];
}

export const DRAGGABLE_SYMBOL_LIST: DraggableSymbolEntry[] = [
  {name: 'curve', label: 'curve', edit: false, type: FigureSymbolType.CURVE, dashed: false},
  {name: 'dashed-curve', label: 'dashedCurve', edit: false, type: FigureSymbolType.CURVE, dashed: true},
  {name: 'line', label: 'line', edit: false, type: FigureSymbolType.LINE, dashed: false},
  {name: 'dashed-line', label: 'dashedLine', edit: false, type: FigureSymbolType.LINE, dashed: true},
  {name: 'arrow', label: 'arrow', edit: false, type: FigureSymbolType.ARROW, dashed: false},
  {name: 'dashed-arrow', label: 'dashedArrow', edit: false, type: FigureSymbolType.ARROW, dashed: true},
  {name: 'brace', label: 'brace', edit: false, type: FigureSymbolType.BRACE, braceType: BraceType.FULL},
  {name: 'bottom-open-brace', label: 'bottomOpenBrace', edit: false, type: FigureSymbolType.BRACE, braceType: BraceType.OPEN_BOTTOM},
  {name: 'top-open-brace', label: 'topOpenBrace', edit: false, type: FigureSymbolType.BRACE, braceType: BraceType.OPEN_TOP},
];

export const DRAGGABLE_HELP_LINE_LIST: any[] = [
  {
    name: 'horizontal-help-line',
    label: 'horizontalHelpLine',
    edit: false,
    type: FigureSymbolType.HELP_LINE,
    orientation: Orientation.HORIZONTAL
  },
  {
    name: 'vertical-help-line',
    label: 'verticalHelpLine',
    edit: false,
    type: FigureSymbolType.HELP_LINE,
    orientation: Orientation.VERTICAL
  },
];
