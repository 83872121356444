<template>
  <span>
    <b-dropdown position="is-bottom-left" :mobile-modal="false" append-to-body aria-role="menu" trap-focus :disabled="isLoading">
      <template #trigger="{}">
        <b-button class="download-docx-button icon-button"
                  :class="(isLoading ? 'loading-cursor' : 'download-cursor')"
                  :title="$t('download.docx.tooltip')"
                  :disabled="isLoading">
          <i class="exi" :class="(isLoading ? 'exi-small-spinner-unmasked rotating' : 'exi-download')"/>
        </b-button>
      </template>
      <b-dropdown-item key="withFigures" aria-role="listitem" @click="downloadDocx(true)">
        {{ $t(`download.docx.withFigures`) }}
      </b-dropdown-item>
      <b-dropdown-item key="withoutFigures" aria-role="listitem" @click="downloadDocx(false)">
        {{ $t(`download.docx.withoutFigures`) }}
      </b-dropdown-item>
    </b-dropdown>

    <ConfirmationDialog ref="defectExportImagesDialog"/>
  </span>
</template>

<script lang="ts">

import {Component, Prop, Ref, toNative, Vue} from 'vue-facing-decorator';
import {ErrorWrapper} from '@/util/error.wrapper';
import {LocalizedMessageKey} from '@/api/models/exception.model';
import ConfirmationDialog, {ConfirmationDialog as ConfirmationDialogClass} from '@/components/common/ConfirmationDialog.vue';
import {WorklistApplicationDocument} from '@/api/models/worklist.model';
import {CreateDocxDocument} from '@/api/services/worklist.api';

@Component(
  {
    components: {
      ConfirmationDialog
    }
  })
class WorklistDownloadButton extends Vue {
  @Prop() applicationDocument!: WorklistApplicationDocument;
  @Ref('defectExportImagesDialog') private defectExportImagesDialog!: ConfirmationDialogClass;

  private isLoading = false;
  private documentExportError = new ErrorWrapper(LocalizedMessageKey.DOCUMENT_EXPORT_ERROR);

  /**
   * Download DOCX export.
   *
   * @param force      if true, DOCX is created even if figures are missing; otherwise an exception is thrown
   * @param singleFile if true, DOCX with text and figures is created;
   *                   otherwise text and figures are in separate *.docx files zipped together
   */
  private downloadDocxInternal(force: boolean, singleFile: boolean): void {
    this.isLoading = true;

    CreateDocxDocument(this.applicationDocument, force, singleFile)
      .catch(this.handleError)
      .finally(() => {
        this.isLoading = false;
      });
  }

  private downloadDocx(singleFile: boolean): void {
    this.downloadDocxInternal(false, singleFile);
  }

  private async handleError(error: any): Promise<void> {
    // If it's the specific DEFECT_EXPORT_IMAGES error, show a dialog with options.
    if (error?.localizedMessage?.key == 'DEFECT_EXPORT_IMAGES') {
      this.showDefectExportImagesDialog();
    } else {
      this.documentExportError.throwError([this.applicationDocument.genericTerm.term]);
    }
  }

  private showDefectExportImagesDialog() {
    // If figures are still missing we give two options:
    // - Cancel
    // - Retry with force flag
    this.defectExportImagesDialog.open(
      {
        titleKey: 'exportErrorDialog.title',
        questionKey: 'exportErrorDialog.info',
        options: [
          {
            labelKey: 'exportErrorDialog.optionForce',
            class: 'button-delete',
            callback: () => {
              this.downloadDocxInternal(true, true);
            },
          }, {
            labelKey: 'general.cancel',
            class: 'button-cancel',
          }]
      });
  }
}

export default toNative(WorklistDownloadButton);
</script>

<style lang="scss" scoped>
@import 'src/assets/styles/colors';
@import 'src/assets/styles/constants';

.dropdown.is-disabled {
  opacity: 1;
}

.download-docx-button {
  margin-left: 4px;
  border: none;
  background-color: transparent;
  padding: 0;
  height: 20px;

  &:disabled {
    color: $text-color;
    background-color: transparent;
    opacity: 1;
  }
}

.loading-cursor {
  cursor: default;
}

.download-cursor {
  cursor: pointer;
}
</style>
