import {CreateExcludeStemFormDto, ExcludedStemForm} from '@/api/models/excludedStemForm.model';
import {apiRequest, ApiResponse} from '@/api/services/common.api';
import {queuedAxiosInstance} from '@/api';

const EXCLUDED_STEM_FORM_PATH = 'excludedStemForm';

export const CreateExcludedStemForm = async (dto: CreateExcludeStemFormDto): Promise<ApiResponse<ExcludedStemForm>> => {
  return apiRequest(queuedAxiosInstance.post(`${EXCLUDED_STEM_FORM_PATH}`, dto));
}

export const DeleteExcludedStemForm = async (guid: string): Promise<ApiResponse<void>> => {
  return apiRequest(queuedAxiosInstance.delete(`${EXCLUDED_STEM_FORM_PATH}/${guid}`));
}
