import {Permission} from '@/api/models/permission.model';
import {HeaderButton, HeaderDropdownItem, HeaderSwitchOptions, HeaderTab} from '@/components/header/header.model';
import EditorModule from '@/store/modules/EditorModule';
import SpellcheckModule from '@/store/modules/SpellcheckModule';
import ApplicationModule from '@/store/modules/ApplicationModule';
import AuthModule from '@/store/modules/AuthModule';
import {ApplicationViewSplitMode} from '@/store/models/application.model';
import {DocumentEditorSplitMode, TwoDocumentEditorsSplitMode} from '@/api/models/editor.model';
import UserProfileModule from '@/store/modules/UserProfileModule';
import {useDefaultErrorHandling} from '@/errorHandling';
import {CreateDocxDocument} from '@/api/services/worklist.api';

const createDropdownItem = (dropdownKeyOff: string, type: string, classNames?: string, callback?: (item?: HeaderDropdownItem) => Promise<void>,
                            options?: string[], dropdownKeyOnOrIcon?: string, defaultSwitchValue?: boolean): HeaderDropdownItem => {
  const item: HeaderDropdownItem = {
    id: 0,
    label: dropdownKeyOff,
    tooltip: dropdownKeyOff,
    type,
    func: callback ? callback : async (item?: HeaderDropdownItem): Promise<void> =>
      console.log('Clicked ' + dropdownKeyOff + ' drop down item! Value: ' + item?.value)
  };
  // Add optional class names if present
  if (classNames) {
    item.class = classNames;
  }
  // Add optional item type specific options if present
  if (options && options.length > 0) {
    item.options = {type: options[0]} as HeaderSwitchOptions;
  }
  // If it is a switch it also has alternative text for the ON-state and a default defaultSwitchValue
  if (dropdownKeyOnOrIcon) {
    if (type === 'slider') {
      item.labelOn = dropdownKeyOnOrIcon;
      item.tooltipOn = item.labelOn;
    } else {
      item.icon = dropdownKeyOnOrIcon;
    }
  }
  item.value = defaultSwitchValue ? true : false;
  return item
}
const createButton = (tabAndButtonKey: string | (() => string), icon: string | (() => string), classNames?: string,
                      callback?: () => Promise<void>, dropdownList?: (string | ((item?: HeaderDropdownItem) => void) | string[] | boolean)[][]): HeaderButton => {
  let dropdownItemID = 0;
  const button: HeaderButton = {
    id: 0,
    label: tabAndButtonKey,
    tooltip: tabAndButtonKey,
    icon: icon
  };
  // Add optional class names if present
  if (callback) {
    button.func = callback;
  }
  // Add optional class names if present
  if (classNames) {
    button.class = classNames;
  }
  // Add optional dropdown item list if present
  if (dropdownList) {
    button.dropdownList = dropdownList.map((item): HeaderDropdownItem => {
      const type = item[1] as string;
      let dropdownKeyOnOrIcon = undefined;
      if (item.length > 5) {
        dropdownKeyOnOrIcon = item[5] as string;
        if (type === 'slider') {
          dropdownKeyOnOrIcon = tabAndButtonKey + '.' + dropdownKeyOnOrIcon;
        }
      }
      return ({
        ...createDropdownItem(tabAndButtonKey + '.' + item[0] as string, // If Switch: Key for OFF
                              type,
                              item.length > 2 ? item[2] as string : undefined,
                              item.length > 3 ? (item[3] as ((headerDropdownItem?: HeaderDropdownItem) => Promise<void>)) : undefined,
                              item.length > 4 ? item[4] as string[] : undefined,
                              dropdownKeyOnOrIcon, // If Switch: Key for ON
                              item.length > 6 ? item[6] as boolean : false), // Default value
        id: dropdownItemID++
      });
    })
  }
  return button
}
const createTab = (tabKey: string, buttons: (string | (() => void) | undefined | (string | ((headerDropdownItem?: HeaderDropdownItem) => void) | string[] | boolean | (() => string))[][])[][]): HeaderTab => {
  let buttonID = 0;
  return {
    id: 0,
    label: tabKey,
    buttons: buttons.map((button): HeaderButton | 'separator' => {
      if (button[0] === 'separator') {
        return 'separator';
      }
      return ({
        ...createButton(button[0] as (string | (() => string)), button[1] as (string | (() => string)),
                        button.length > 2 ? button[2] as string : undefined,
                        button.length > 3 ? (button[3] as (() => Promise<void>) | undefined) : undefined,
                        button.length > 4
                          ? (button[4] as (string | ((headerDropdownItem?: HeaderDropdownItem) => void) | string[] | boolean)[][])
                          : undefined),
        id: buttonID++
      });
    })
  }
}
const createTabs = (tabsAndButtons: (string | (string | (() => void) | undefined | (string | ((headerDropdownItem?: HeaderDropdownItem) => void) | string[] | boolean)[][] | ((state: any) => string))[][])[][]): HeaderTab[] => {
  let tabID = 0;
  return tabsAndButtons.map((tab): HeaderTab => ({
    ...createTab(tab[0] as string,
                 tab[1] as (string | (() => void) | undefined | (string | ((headerDropdownItem?: HeaderDropdownItem) => void) | string[] | boolean)[][])[][]),
    id: tabID++
  }))
    // Only return tabs that at least have one button
    .filter((tab: HeaderTab) => tab.buttons.length > 0);
}

/**
 * This file holds all the information about what will be displayed when in the header bar
 */
export const getHeaderTabs = (routeName: string, permissions: Array<Permission>): HeaderTab[] => {

  const getApplicationViewSplitStateName = (): string => {
    const splitState = ApplicationModule.applicationViewSplitState;
    switch (splitState.mode) {
      case ApplicationViewSplitMode.DOCUMENT_ONLY:
        return 'showFigureEditor';
      case ApplicationViewSplitMode.DOCUMENT_AND_FIGURES:
        return 'hideFigureEditor';
    }
  };

  const getApplicationViewSplitStateIcon = (): string => {
    const splitState = ApplicationModule.applicationViewSplitState;
    switch (splitState.mode) {
      case ApplicationViewSplitMode.DOCUMENT_ONLY:
        return 'exi-application-view-show-figureeditor';
      case ApplicationViewSplitMode.DOCUMENT_AND_FIGURES:
        return 'exi-application-view-hide-figureeditor';
    }
  };

  const firstSplitEditorIconText = (): string => {
    const splitState = UserProfileModule.modeAndSplit;
    switch (splitState.mode) {
      case DocumentEditorSplitMode.ONE :
        return 'splitTextHorizontally';
      case DocumentEditorSplitMode.TWO: {
        switch (splitState.split) {
          case undefined:
            return 'splitTextHorizontally';
          case TwoDocumentEditorsSplitMode.SPLIT_HORIZONTALLY:
            return 'splitTextVertically';
          case TwoDocumentEditorsSplitMode.SPLIT_VERTICALLY:
            return 'splitTextHorizontally';
        }
      }
    }
  }

  const firstSplitEditorIcon = (): string => {
    const splitState = UserProfileModule.modeAndSplit;
    switch (splitState.mode) {
      case DocumentEditorSplitMode.ONE:
        return 'exi-editor-horizontal-split';
      case DocumentEditorSplitMode.TWO: {
        switch (splitState.split) {
          case undefined:
            return 'exi-editor-horizontal-split';
          case TwoDocumentEditorsSplitMode.SPLIT_HORIZONTALLY:
            return 'exi-editor-vertical-split';
          case TwoDocumentEditorsSplitMode.SPLIT_VERTICALLY:
            return 'exi-editor-horizontal-split';
        }
      }
    }
  }

  const firstSplitEditorIconFunc = (): void => {
    const splitState = UserProfileModule.modeAndSplit;
    switch (splitState.mode) {
      case DocumentEditorSplitMode.ONE:
        EditorModule.toggleToHorizontalSplitMode().catch(useDefaultErrorHandling);
        break;
      case DocumentEditorSplitMode.TWO: {
        switch (splitState.split) {
          case undefined:
            EditorModule.toggleToHorizontalSplitMode().catch(useDefaultErrorHandling);
            break;
          case TwoDocumentEditorsSplitMode.SPLIT_HORIZONTALLY:
            EditorModule.toggleToVerticalSplitMode().catch(useDefaultErrorHandling);
            break;
          case TwoDocumentEditorsSplitMode.SPLIT_VERTICALLY:
            EditorModule.toggleToHorizontalSplitMode().catch(useDefaultErrorHandling);
            break;
        }
      }
    }
  }

  const secondSplitEditorIconText = (): string => {
    const splitState = UserProfileModule.modeAndSplit;
    switch (splitState.mode) {
      case DocumentEditorSplitMode.ONE:
        return 'splitTextVertically';
      case DocumentEditorSplitMode.TWO: {
        switch (splitState.split) {
          case undefined:
            return 'splitTextVertically';
          case TwoDocumentEditorsSplitMode.SPLIT_HORIZONTALLY:
            return 'removeSplit';
          case TwoDocumentEditorsSplitMode.SPLIT_VERTICALLY:
            return 'removeSplit';
        }
      }
    }
  }

  const secondSplitEditorIcon = (): string => {
    const splitState = UserProfileModule.modeAndSplit;
    switch (splitState.mode) {
      case DocumentEditorSplitMode.ONE:
        return 'exi-editor-vertical-split';
      case DocumentEditorSplitMode.TWO: {
        switch (splitState.split) {
          case undefined:
            return 'exi-editor-vertical-split'
          case TwoDocumentEditorsSplitMode.SPLIT_HORIZONTALLY:
            return 'exi-editor-no-split';
          case TwoDocumentEditorsSplitMode.SPLIT_VERTICALLY:
            return 'exi-editor-no-split';
        }
      }
    }
  }

  const secondSplitEditorIconFunc = (): void => {
    const splitState = UserProfileModule.modeAndSplit;
    switch (splitState.mode) {
      case DocumentEditorSplitMode.ONE:
        EditorModule.toggleToVerticalSplitMode().catch(useDefaultErrorHandling);
        break;
      case DocumentEditorSplitMode.TWO: {
        switch (splitState.split) {
          case TwoDocumentEditorsSplitMode.SPLIT_HORIZONTALLY:
            EditorModule.toggleToSingleSplitMode().catch(useDefaultErrorHandling);
            break;
          case TwoDocumentEditorsSplitMode.SPLIT_VERTICALLY:
            EditorModule.toggleToSingleSplitMode().catch(useDefaultErrorHandling);
            break;
        }
      }
    }
  }

  const tabAndButtonDefs = [
    [
      'application', [
      ['edit', 'exi-pen', '', () => ApplicationModule.setShowEditDialog(true)],
      [
        'export', 'exi-download', '', undefined, [
        [
          'withFigures', 'button', '',
          () => CreateDocxDocument(ApplicationModule.currentApplicationDocument!, false, true), [], ''],
        [
          'withoutFigures', 'button', '',
          () => CreateDocxDocument(ApplicationModule.currentApplicationDocument!, false, false), [], ''],
      ]]
    ]],
    [
      'compose', [
      [
        'search', 'exi-search', '',
        () => EditorModule.toggleSearchBar()],
      [
        'spellingAndGrammar', 'exi-spellcheck', '', undefined, [
        [
          'inactive', 'slider', '', (item?: HeaderDropdownItem) => SpellcheckModule.toggleSpellcheck(item), ['is-success'], 'active',
          SpellcheckModule.isActive],
        ['resetIgnoredWords', 'button', '', () => SpellcheckModule.triggerDeleteAllWordsFromIgnoreList(), [], 'exi-ignore'],
        ['dictionary', 'button', '', () => EditorModule.setOpenSpellcheckDictionaryManagement(true), [], 'exi-dictionary']
      ]]
    ]],
    [
      'view', [
      [firstSplitEditorIconText, firstSplitEditorIcon, '', () => firstSplitEditorIconFunc()],
      [secondSplitEditorIconText, secondSplitEditorIcon, '', () => secondSplitEditorIconFunc()],
      ['separator'],
      [getApplicationViewSplitStateName, getApplicationViewSplitStateIcon, '',
        () => ApplicationModule.toggleApplicationSplitViewState(AuthModule.user!.id)],
      ['separator'],
      ['resetTextZoom', 'exi-zoom-doc-100', '', () => EditorModule.setZoomLevel(1.0)]
    ]]
  ];

  // For the Application Document Editor
  const headerTabsApplication: HeaderTab[] = createTabs(tabAndButtonDefs);

  const routeTabs: { [key: string]: HeaderTab[] } = {
    'worklist': [], // TODO
    'editor': headerTabsApplication
  };

  if (routeTabs[routeName]) {
    return routeTabs[routeName];
  }

  return [];
};
