import {ComponentPublicInstance} from 'vue';
import axios, {AxiosError, AxiosInstance, AxiosRequestHeaders, AxiosResponse, InternalAxiosRequestConfig} from 'axios';
import {LocalizedMessageKey, PatentEngineException} from '@/api/models/exception.model';
import {ToastProgrammatic as Toast} from '@ntohq/buefy-next';
import {createFirstInFirstOutAxiosInstance} from '@/api/services/RequestQueue';
import store from '@/store';
import {triggerLogin} from '@/api/services/auth.api';
import PermissionModule from '@/store/modules/PermissionModule';
import i18n from '@/i18n';
import log from 'loglevel';
import {SPELLCHECK_PATH} from '@/api/services/spellcheck.api';
import {GetLocalizedExceptionMessage, GetLocalizedExceptionMessageFor} from '@/api/services/exceptions';

export const defaultRequestTimeout = 60000; // TODO reduce timeout (increased for "Generate all", maybe we need seperate axios instance for this)
export const undoRedoCommandStepTimeout = 5000; // additional ms per step to undo/redo (up to max)
export const undoRedoCommandMaxTimeout = 60000; // max timeout for multiple undo/redo steps

const showTestErrorToast = false; // To display an error toast on every request for debugging and testing

const defaultAxiosConfig: InternalAxiosRequestConfig = {
  baseURL: process.env.VUE_APP_API_URL,
  timeout: defaultRequestTimeout,
  decompress: false,
  headers: {
    Accept: 'application/json'
  } as AxiosRequestHeaders
};

/*const AuthInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const accessToken = User.authToken;
  if (accessToken) config.headers.Authorization = `Token ${accessToken}`;
  return config;
};*/

const globalExceptionMap: { [message: string]: ComponentPublicInstance | undefined } = {};

export function showToast(clientLocalizedMessage: string) {
  const duration = 5000;
  const toast = new Toast().open({
                                   duration: duration,
                                   message: clientLocalizedMessage,
                                   position: 'is-bottom',
                                   type: 'is-danger'
                                 });
  globalExceptionMap[clientLocalizedMessage] = toast;

  // For some reason toast.$destroy no longer gets called. So we use a timeout to remove the mapping.
  setTimeout(() => {
    globalExceptionMap[clientLocalizedMessage] = undefined;
  }, duration);

  return toast;
}

const handleException = (patentEngineException: PatentEngineException): ComponentPublicInstance | undefined => {

  // If it is a Blob failed downloading, ignore it. An additional exception should be delivered
  if (patentEngineException instanceof Blob) {
    return undefined;
  }

  // We don't want to show error toasts when spellcheck requests (to the spellcheck server) fail
  const errorKey = patentEngineException?.localizedMessage?.key;
  if ((errorKey === LocalizedMessageKey.SPELLCHECK_ERROR) || (errorKey === LocalizedMessageKey.SPELLCHECK_FAILED)) {
    return undefined;
  }

  if(log.getLevel() <= log.levels.WARN) {
    log.warn('Request - Exception', patentEngineException);
  }

  let clientLocalizedMessage: string;
  let consoleMessage: string;

  // In case we have a known exception with localized message key
  if (patentEngineException.localizedMessage && errorKey) {
    // Helper function to translate an entity name (that would be the first argument)
    const translateEntity = (patentEngineException: PatentEngineException): void => {
      patentEngineException.localizedMessage.values[0] = i18n.global.t('entity.' + patentEngineException.localizedMessage.values[0]);
    }

    switch (errorKey) {
      // Special cases of keys
      case LocalizedMessageKey.MODEL_NOT_FOUND:
      case LocalizedMessageKey.INVALID_ENTITY: {
        translateEntity(patentEngineException);
        break;
      }
    }

    // Translate it with values
    clientLocalizedMessage = GetLocalizedExceptionMessageFor(patentEngineException.localizedMessage);
    consoleMessage = clientLocalizedMessage;
  } else {
    clientLocalizedMessage = GetLocalizedExceptionMessage(patentEngineException.code ? 'UNDEFINED' : 'NETWORK_ERROR');
    consoleMessage = patentEngineException.message;
  }

  // If the exact same error is already displayed at the moment, cancel
  if (globalExceptionMap[clientLocalizedMessage] != undefined) {
    return undefined;
  }

  // Also display it on the console
  if (consoleMessage != undefined) {
    console.error(consoleMessage);
  }

  // Display the toast
  return showToast(clientLocalizedMessage);
};

const OnResponseSuccess = (response: AxiosResponse): AxiosResponse | Promise<AxiosResponse> => {

  if(log.getLevel() <= log.levels.INFO && !response.config.url?.includes(SPELLCHECK_PATH)) {
    log.info('Request - Success', response.config);
  }

  // Displaying an error toast for testing and debugging
  if (showTestErrorToast) {
    handleException(
      {
        code: 42,
        message: 'Test',
        localizedMessage: {
          key: LocalizedMessageKey.TEST_ERROR,
          values: []
        }
      });
  }

  // Hide the splash screen
  PermissionModule.increaseSuccessfulResponses();
  return response;
};

// eslint-disable-next-line
const OnResponseFailure = (error: AxiosError): Promise<any> => {
  if (axios.isCancel(error)) {
    return Promise.reject(error);
  }

  if (axios.isAxiosError(error)) {
    const axiosError = error as AxiosError;

    const httpStatus = axiosError?.response?.status;

    switch (httpStatus) {
      case 401: // UNAUTHORIZED
        triggerLogin(store.state);
        break;
      case -42:
        // Special case: Our axios instance decided to not send this request at all
        break;
      case 422:
        // special case for ai-assistant. server uses 422 to mark a syntactically invalid template
        if(axiosError.config?.url?.includes('/chat/completion')){
          return Promise.reject(((axiosError.response ? axiosError.response.data : error) as PatentEngineException).localizedMessage);
        }else {
          return Promise.resolve(axiosError.response);
        }
      default:
        handleException((axiosError.response ? axiosError.response.data : error) as PatentEngineException);
        // const errors = transformErrors(response?.response?.data?.errors);
        break;
    }
  }

  /*const isUnknownError = errors?.[0].startsWith('Unknown');
  switch (httpStatus) {
    case HttpStatusCodes.UNAUTHORIZED:
        User.logout();
        router.push({name: routesNames.authLogin});
        notifyWarn('You are not logged in, please login first.');
        break;
    case HttpStatusCodes.NOT_FOUND:
        notifyErrors(
            errors?.length > 0 && !isUnknownError
                ? errors
                : ['Requested resource was not found.']
        );
        break;
    case HttpStatusCodes.FORBIDDEN:
        notifyErrors(
            errors?.length > 0 && !isUnknownError
                ? errors
                : ['Access to this resource is forbidden']
        );
        break;
    case HttpStatusCodes.UNPROCESSABLE_ENTITY:
        // This case should be handled at the forms
        break;
    default:
        notifyErrors(
            errors?.length > 0
                ? errors
                : ['Unknown response occurred, please try again later.']
        );
        break;
  }*/
  return Promise.reject(error);
};

// Use this to get the same effect like backend errors -> Showing toast via error key
// eslint-disable-next-line
export const ThrowError = (key: LocalizedMessageKey, values?: any[]): ComponentPublicInstance | undefined => handleException(
  {
    code: -1,
    message: '',
    localizedMessage: {key, values: values ? values : []}
  });

// Use this to display a success toast via message key
// eslint-disable-next-line
export const DisplaySuccessMessage = (key: string, values?: string[]): ComponentPublicInstance | undefined => {
  return new Toast().open({
                            duration: 5000,
                            message: values ? i18n.global.t(key, values) : i18n.global.t(key),
                            position: 'is-bottom',
                            type: 'is-success'
                          });
};
export const cancelToken = axios.CancelToken;
// Create and setup default axios instance
export const axiosInstance: Readonly<AxiosInstance> = axios.create(defaultAxiosConfig);
// axiosInstance.interceptors.request.use(AuthInterceptor);
axiosInstance.interceptors.response.use(
  OnResponseSuccess,
  OnResponseFailure);

// Create and setup axios instance for files
export const axiosLlmRequestInstance: Readonly<AxiosInstance> = axios.create(
  {
    ...defaultAxiosConfig,
    timeout: 180000
  });
axiosLlmRequestInstance.interceptors.response.use(OnResponseSuccess, OnResponseFailure);

export const axiosBlobInstance: Readonly<AxiosInstance> = axios.create(
  {
    ...defaultAxiosConfig,
    timeout: 60000,
    headers: {},
    responseType: 'blob',
  });
axiosBlobInstance.interceptors.response.use(
  OnResponseSuccess,
  OnResponseFailure);

// Create and setup axios instance for figure preview (with higher timeout)
export const axiosFigurePreviewInstance: Readonly<AxiosInstance> = axios.create(
  {
    ...defaultAxiosConfig,
    timeout: 60000,
  });
axiosFigurePreviewInstance.interceptors.response.use(
  OnResponseSuccess,
  OnResponseFailure);

// Create and setup queued axios instance
export const queuedAxiosInstance: Readonly<AxiosInstance> = createFirstInFirstOutAxiosInstance(
  defaultAxiosConfig,
  OnResponseSuccess,
  OnResponseFailure);

export const queuedFigureEditorAxiosInstance: Readonly<AxiosInstance> = createFirstInFirstOutAxiosInstance(
  defaultAxiosConfig,
  OnResponseSuccess,
  OnResponseFailure);
