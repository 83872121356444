import {
  CreateReferenceSignEvent,
  ExcludeStemFormMatchDto,
  ReferenceSign,
  ReferenceSignCreatedVmUpdate,
  ReferenceSignUpdatedVmUpdate,
  StemFormMatchExcludedViewModel,
  StemFormsGeneratedViewModel,
  UpdateReferenceSignEvent
} from '@/api/models/referenceSign.model';
import {axiosInstance} from '@/api';
import {apiRequest, ApiResponse} from '@/api/services/common.api';

const REFERENCE_SIGN_PATH = '/referencesign';

export const GetReferenceSign = async (guid: string): Promise<ReferenceSign> => {
    const res = await axiosInstance.get(`${REFERENCE_SIGN_PATH}/${guid}`);
    return res?.data as ReferenceSign;
};

export const CreateReferenceSign = async (event: CreateReferenceSignEvent): Promise<ReferenceSign> => {
    const res = await axiosInstance.post(REFERENCE_SIGN_PATH, event);
    return (res?.data as ReferenceSignCreatedVmUpdate).referenceSign;
};

export const GenerateStemForms = async (guid: string): Promise<StemFormsGeneratedViewModel> => {
  const res = await axiosInstance.post(`${REFERENCE_SIGN_PATH}/${guid}/generateStemForms`);
  return res?.data as StemFormsGeneratedViewModel;
}

export const ExcludeStemFormMatch = async (excludeStemFormMatchDto: ExcludeStemFormMatchDto): Promise<ApiResponse<StemFormMatchExcludedViewModel>> => {
  return apiRequest(axiosInstance.post(`${REFERENCE_SIGN_PATH}/stemFormMatch/exclude`, excludeStemFormMatchDto));
}

export const UpdateReferenceSign = async (event: UpdateReferenceSignEvent): Promise<ReferenceSignUpdatedVmUpdate> => {
  const res = await axiosInstance.put(REFERENCE_SIGN_PATH, event);
  return res?.data as ReferenceSignUpdatedVmUpdate;
};

export const DeleteReferenceSign = async (guid: string): Promise<ApiResponse<void>> => {
  return apiRequest(axiosInstance.delete(`${REFERENCE_SIGN_PATH}/${guid}`));
};

