import {CreateStemFormDto, StemForm} from '@/api/models/stemForm.model';
import {apiRequest, ApiResponse} from '@/api/services/common.api';
import {axiosInstance} from '@/api';

const STEM_FORM_PATH = 'stemForm';

export const CreateStemForm = async (dto: CreateStemFormDto): Promise<ApiResponse<StemForm>> => {
  return apiRequest(axiosInstance.post(`${STEM_FORM_PATH}`, dto));
}

export const DeleteStemForm = async (guid: string): Promise<ApiResponse<void>> => {
  return apiRequest(axiosInstance.delete(`${STEM_FORM_PATH}/${guid}`));
}