import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-02e3dfdc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "reference-sign" }
const _hoisted_2 = { class: "stem-form-header" }
const _hoisted_3 = ["title"]
const _hoisted_4 = ["title"]
const _hoisted_5 = ["title"]
const _hoisted_6 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_tag = _resolveComponent("b-tag")!
  const _component_b_taginput = _resolveComponent("b-taginput")!
  const _component_b_field = _resolveComponent("b-field")!
  const _component_modal_dialog = _resolveComponent("modal-dialog")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createVNode(_component_modal_dialog, {
      ref: "reference-sign-dialog",
      showHeader: true,
      showFooter: false,
      width: _ctx.contextWidth,
      margin: _ctx.contextMargin
    }, {
      body: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("label", {
            class: "label",
            title: _ctx.$t('searchStemFormTitle')
          }, _toDisplayString(_ctx.$t('searchStemForm')), 9, _hoisted_3),
          _createElementVNode("button", {
            class: "icon-word-stem-refresh icon-button",
            title: _ctx.$t('searchStemRefresh'),
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.refreshSearchStems && _ctx.refreshSearchStems(...args)))
          }, [
            _createElementVNode("i", {
              class: _normalizeClass(_ctx.isRefreshing ? 'exi exi-small-spinner-unmasked rotating' : 'exi exi-refresh')
            }, null, 2)
          ], 8, _hoisted_4)
        ]),
        _createVNode(_component_b_field, {
          class: "stem-forms",
          title: _ctx.$t('searchStemFormTitle')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_b_taginput, {
              size: "is-small",
              maxlength: this.maxTagLength,
              ellipsis: "",
              icon: "label",
              "before-adding": _ctx.isNoDuplicateStemFormTag,
              createTag: _ctx.onStemFormCreated,
              onAdd: _ctx.onStemFormAdded,
              onRemove: _ctx.onStemFormRemovedByKey,
              modelValue: this.stemForms,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((this.stemForms) = $event)),
              closable: !_ctx.isLastStemForm(),
              "remove-on-keys": _ctx.bindRemoveKeys(),
              readonly: this.isRefreshing,
              placeholder: _ctx.$t('stemForm'),
              ref: "searchStemFormInput"
            }, {
              selected: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.stemForms, (item, index) => {
                  return (_openBlock(), _createBlock(_component_b_tag, {
                    ellipsis: "",
                    key: index,
                    title: item.stem,
                    type: _ctx.isGeneratedStemForm(item) ? 'tag-style-generated' : 'tag-style-manual',
                    closable: !_ctx.isLastStemForm(),
                    closeType: "stemForms",
                    onClose: ($event: any) => (_ctx.onStemFormRemovedByTagClose(item))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.stem), 1)
                    ]),
                    _: 2
                  }, 1032, ["title", "type", "closable", "onClose"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["maxlength", "before-adding", "createTag", "onAdd", "onRemove", "modelValue", "closable", "remove-on-keys", "readonly", "placeholder"])
          ]),
          _: 1
        }, 8, ["title"]),
        _createElementVNode("label", {
          class: "label",
          title: _ctx.$t('excludeStemFormMatchesTitle')
        }, _toDisplayString(_ctx.$t('excludeStemFormMatches')), 9, _hoisted_5),
        _createVNode(_component_b_field, {
          class: "exclude-stem-form-matches",
          title: _ctx.$t('excludeStemFormMatchesTitle')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_b_taginput, {
              size: "is-small",
              maxlength: this.maxTagLength,
              ellipsis: "",
              icon: "label",
              "before-adding": _ctx.isNoDuplicateExcludedTag,
              "create-tag": _ctx.createExcludedTag,
              onAdd: _ctx.onExcludeStemFormMatchAdded,
              onRemove: _ctx.onExcludeStemFormMatchRemovedByKey,
              modelValue: this.excludeStemFormMatches,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((this.excludeStemFormMatches) = $event)),
              readonly: this.isRefreshing,
              placeholder: _ctx.excludeStemFormMatches.length === 0 ? _ctx.$t('excludeStemFormMatchesTitle') : _ctx.$t('excludeStemFormMatchesPlaceholder')
            }, {
              selected: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.excludeStemFormMatches, (item, index) => {
                  return (_openBlock(), _createBlock(_component_b_tag, {
                    ellipsis: "",
                    key: index,
                    title: item.stem,
                    closable: "",
                    closeType: "excludeStemFormMatches",
                    onClose: ($event: any) => (_ctx.onExcludeStemFormMatchRemovedByTagClose(item))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.stem), 1)
                    ]),
                    _: 2
                  }, 1032, ["title", "onClose"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["maxlength", "before-adding", "create-tag", "onAdd", "onRemove", "modelValue", "readonly", "placeholder"])
          ]),
          _: 1
        }, 8, ["title"]),
        _createElementVNode("label", {
          class: "label",
          title: _ctx.$t('stemFormMatchesTitle')
        }, _toDisplayString(_ctx.$t('stemFormMatches')), 9, _hoisted_6),
        _createVNode(_component_b_field, {
          class: "stem-form-matches",
          title: _ctx.$t('stemFormMatchesTitle')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_b_taginput, {
              size: "is-small",
              readonly: "",
              ellipsis: "",
              modelValue: _ctx.stemFormMatches,
              placeholder: _ctx.stemFormMatches.length === 0 ? _ctx.$t('stemFormMatchesTitle') : ''
            }, {
              selected: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stemFormMatches, (item, index) => {
                  return (_openBlock(), _createBlock(_component_b_tag, {
                    ellipsis: "",
                    ref_for: true,
                    ref: index,
                    key: item.matchWord,
                    type: item.collisionWithOtherReferenceSign ? 'tag-style-warn':'',
                    title: item.matchWord,
                    closable: "",
                    onClose: ($event: any) => (_ctx.onTagExcluded(index)),
                    closeType: "stemFormMatches",
                    tabindex: index
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.matchWord), 1)
                    ]),
                    _: 2
                  }, 1032, ["type", "title", "onClose", "tabindex"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue", "placeholder"])
          ]),
          _: 1
        }, 8, ["title"])
      ]),
      _: 1
    }, 8, ["width", "margin"])
  ]))
}