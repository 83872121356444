import axios, {AxiosError, AxiosResponse} from 'axios';

export type HttpResponseData = {
  status: number;
}

export type ApiResponse<T> = {
  data: T;
  httpResponse: HttpResponseData;
}

function isApiResponseError<T>(error: unknown): error is ApiResponse<T> {
  return (
    typeof error === 'object' &&
    error !== null &&
    'status' in error &&
    'httpResponse' in error
  );
}

function isAxiosError(error: unknown): error is AxiosError {
  return axios.isAxiosError(error);
}

export async function apiRequest<T>(request: Promise<AxiosResponse<T>>): Promise<ApiResponse<T>> {
  try {
    const response = await request;
    return {
      data: response.data,
      httpResponse: {
        status: response.status,
      }
    };
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      throw {
        data: error.response.data,
        httpResponse: {
          status: error.response.status,
        }
      };
    }
    throw error; // Handle network errors or unexpected failures
  }
}
