<template>
  <div :class="'header' + (barPinned ? ' header-with-pinned-bar' : ' header-without-pinned-bar')
             + ((toggleIsActive || barPinned) ? ' header-without-border' : '')"
       v-mouse-margin-leave="{ onMouseLeave: () => onMouseLeave(), margin: 150, shouldCheck: () => toggleIsActiveComputed }">

    <CoverSheetDialog ref="editDialog"
                      :showDefaultButton="false"
                      class="edit-coversheet-icon"
                      :edit-mode="Mode.EDIT"
                      :onClose="onCloseEditDialog"/>

    <ConfirmationDialog ref="defectExportImagesDialog"/>

    <div class="header-main">
      <div class="header-bar-container">
        <b-dropdown ref="barToggle" aria-role="menu" @active-change="toggleActiveChange" :can-close="canCloseHeaderBar"
                    :triggers="onlyTriggerWhenHeaderTabsExist()"
                    :mobile-modal="false"
                    animation="slide">

          <template #trigger>
            <b-button type="is-info" class="navbar-item icon-button" v-show="!barPinned && !toggleIsActive && headerTabs.length > 0"
                      :title="$t('header.toggle')">
              <i class="exi exi-chevron-down"></i>
            </b-button>
          </template>
          <b-dropdown-item class="header-bar-panel" aria-role="menu-item" :focusable="true" v-show="!barPinned" custom paddingless>
            <HeaderBar :pinCallback="pin"
                       :tab-name="currentTabname"
                       :buttons="headerBarButtons"
                       :barPinned="barPinned"
                       :toggleIsActive="toggleIsActive"
                       :onOpenConfirmationDialog="onOpenConfirmationDialog"/>
          </b-dropdown-item>
        </b-dropdown>

      </div>
      <div class="header-bar-pinned" v-show="barPinned">
        <HeaderBar :pinCallback="pin"
                   :tab-name="currentTabname"
                   :buttons="headerBarButtons"
                   :barPinned="barPinned"
                   :toggleIsActive="toggleIsActive"
                   :onOpenConfirmationDialog="onOpenConfirmationDialog"/>
      </div>
      <div class="top-container-in-header">
        <div class="left-aligned-header-part">
          <div class="back-link" v-if="showBackButton">
            <a @click="clickBack()" :title="getBackTitle">
              <div class="back-link-content">
                <i class="back-button-icon exi exi-arrow-left"/>
                <div class="back-button-text">{{ getBackText }}</div>
              </div>
            </a>
          </div>

          <div class="header-tabs-container" v-if="headerTabs.length > 0 && (toggleIsActive || barPinned)" @click="headerTabClick()">
            <b-tabs :modelValue="activeHeaderTab" class="header-tabs" @update:modelValue="headerTabChanged($event)">
              <template v-for="tab in headerTabs" :key="tab.id">
                <!--                <span :key="tab.id">{{getTabLabel(tab.label)}}</span>-->
                <b-tab-item :value="String(tab.id)" :label="getTabLabel(tab.label)"/>
              </template>
            </b-tabs>
          </div>

          <div class="menu-button-container" v-if="!showBackButton">
            <button class="icon-button" :title="$t('sidebar.title')" @click="sidebar.open = !sidebar.open">
              <i class="sidebar-button-icon exi exi-menu"/>
            </button>
          </div>
        </div>

        <div class="logo-and-user-container">
          <LogoMenu/>
          <UserMenu/>
        </div>
      </div>
      <Sidebar ref="sidebar"/>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Ref, toNative, Vue, Watch} from 'vue-facing-decorator';
import Sidebar, {Sidebar as SidebarClass} from '@/components/Sidebar.vue';
import HeaderBar from '@/components/header/HeaderBar.vue';
import router from '@/router';
import {getHeaderTabs} from '@/components/header/header.bar.data';
import HeaderModule from '@/store/modules/HeaderModule';
import {Permission} from '@/api/models/permission.model';
import PermissionModule from '@/store/modules/PermissionModule';
import {HeaderButton, HeaderTab} from '@/components/header/header.model';
import UserMenu from '@/components/header/UserMenu.vue';
import LogoMenu from '@/components/header/LogoMenu.vue';
import {SplashScreenDialog as SplashScreenDialogClass} from '@/components/SplashScreenDialog.vue';
import {nextTick} from 'vue';
import WorklistModule from '@/store/modules/WorklistModule';
import CoverSheetDialog, {CoverSheetDialog as CoverSheetDialogClass} from '@/views/CoverSheetDialog.vue';
import ApplicationModule from '@/store/modules/ApplicationModule';
import {Mode} from '@/util/enums';
import ConfirmationDialog, {ConfirmationDialog as ConfirmationDialogClass} from '@/components/common/ConfirmationDialog.vue';
import {ConfirmationDialogParameters} from '@/store/models/confirmationDialog.model';

@Component(
  {
    components: {
      LogoMenu,
      UserMenu,
      Sidebar,
      HeaderBar,
      CoverSheetDialog,
      ConfirmationDialog
    }
  })
class Header extends Vue {
  @Ref('editDialog') dialog!: CoverSheetDialogClass;
  @Prop() private splashScreen!: SplashScreenDialogClass;
  @Ref('sidebar') private sidebar!: SidebarClass;
  @Ref('barToggle') private barToggle!: { isActive: boolean }; // BDropdown;
  @Ref('defectExportImagesDialog') private defectExportImagesDialog!: ConfirmationDialogClass;

  private routeName = '';
  private permissions: Array<Permission> = [];
  private headerTabs: HeaderTab[] = [];
  private currentTabname = '';
  private headerBarButtons: (HeaderButton | 'separator')[] = [];
  private barPinned = false;
  private toggleIsActive = false;
  private canCloseHeaderBar = true;

  get Mode() {
    return Mode;
  }

  get showEditDialog() {
    return ApplicationModule.showEditDialog;
  }

  @Watch("showEditDialog")
  showEditDialogChanged(showEditDialog: boolean) {
    if (showEditDialog) {
      this.dialog.open();
    } else {
      this.dialog.close();
    }
  }

  onOpenConfirmationDialog(params: ConfirmationDialogParameters) {
    this.defectExportImagesDialog.open(params);
  }

  onCloseEditDialog() {
    ApplicationModule.setShowEditDialog(false);
  }

  get toggleIsActiveComputed() {
    return this.toggleIsActive;
  }

  onlyTriggerWhenHeaderTabsExist(): string[] {
    if (this.headerTabs.length > 0) {
      return ['click'];
    }
    return [];
  }

  get showBackButton(): boolean {
    return this.$route.name != 'worklist';
  }

  get getBackText(): string {
    switch (this.$route.name) {
      case 'editor':
      case 'library-management':
        return `${this.$t('worklist')}`;
      case 'figure': {
        return `${this.$t('editorTitle')}`;
      }
    }

    return ''
  }

  get getBackTitle(): string {
    switch (this.$route.name) {
      case 'editor':
      case 'library-management':
        return `${this.$t('general.backTo')} ${this.$t('worklist')}`;
      case 'figure': {
        return `${this.$t('general.backToAlt')} ${this.$t('editorTitle')}`;
      }
    }

    return ''
  }

  get getBackPathTo(): string {
    let pathTo = '';
    switch (this.$route.name) {
      case 'editor':
      case 'library-management':
        pathTo = '/'
        break;
      case 'figure': {
        pathTo = `/application/${this.$route.params.applicationGuid}`;
        break;
      }
    }
    return pathTo;
  }

  get getPermissions(): Array<Permission> {
    this.permissions = PermissionModule.permissions;
    return this.permissions;
  }

  @Watch("getPermissions", {immediate: true})
  private onPermissionsChange(): void {
    this.determineTabs();
  }

  getTabLabel(tabKey: string): string {
    return this.$t('header.tab.' + tabKey + '.label') as string
  }

  private clickBack(): void {
    // Fix: Reset documents
    WorklistModule.setApplicationDocuments([]);

    // Display the splash screen when changing the route
    if (this.splashScreen) {
      this.splashScreen.open();
    }
    router.push({path: this.getBackPathTo});
  }

  private headerTabClick(): void {
    if (this.toggleIsActive || this.barPinned) {
      this.canCloseHeaderBar = false;
      setTimeout(() => this.canCloseHeaderBar = true, 100);
    }
  }

  @Watch('activeHeaderTab', {immediate: true})
  private onHeaderTabChange(newValue: number): void {
    this.populateHeaderButtons(newValue);
  }

  private populateHeaderButtons(newValue: number) {
    const headerTabs = this.headerTabs[newValue];
    this.currentTabname = headerTabs ? this.headerTabs[newValue].label : '';
    this.headerBarButtons = headerTabs ? headerTabs.buttons : [];
  }

  private headerTabChanged(tabValue: number): void {
    HeaderModule.setActiveTab(tabValue);
  }

  @Watch('$i18n.locale')
  private onLocaleChange(): void {
    this.determineTabs();
  }

  private get activeHeaderTab(): number {
    return HeaderModule.activeTab;
  }

  private toggleActiveChange(active: boolean): void {
    this.toggleIsActive = active;
  }

  public pin(): void {
    this.barPinned = !this.barPinned;
    if (this.barPinned) {
      this.barToggle.isActive = false;
    }
    HeaderModule.setHeaderBarStatusForRoute({routeName: this.routeName, barPinned: this.barPinned});
  }

  onMouseLeave(): void {
    if (!this.barPinned && this.barToggle.isActive) {
      this.barToggle.isActive = false;
    }
  }

  // Call this when ever the tabs can change (New route, new permissions, ...)
  private determineTabs(): void {
    this.headerTabs = getHeaderTabs(this.routeName, this.permissions);
  }

  // Call this when ever the route was changed
  private determineRouteTabsAndState(): void {
    this.routeName = this.$router.currentRoute.value.name as string;
    this.determineTabs();

    this.toggleIsActive = false;
    this.barPinned = HeaderModule.pinnedForRoute[this.routeName] == true;
    if (this.barPinned) {
      this.barToggle.isActive = false;
    }
  }

  mounted(): void {
    this.determineRouteTabsAndState();
    this.populateHeaderButtons(HeaderModule.activeTab);

    // After changing the route we use its name to decide the header bar
    router.afterEach((/*to: Route, from: Route*/) => {
      // Use next tick to handle router history correctly
      // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609 <-- dead link
      nextTick(() => {
        this.determineRouteTabsAndState();
        this.populateHeaderButtons(HeaderModule.activeTab);
      });
    });
  }
}

export default toNative(Header);
</script>

<style lang="scss" scoped>
@import 'src/assets/styles/constants';
@import 'src/assets/styles/colors';

.header {
  width: 100%;
  min-width: 1280px;
  height: $header-height !important;

  &:not(.header-without-border) {
    .header-main {
      .top-container-in-header {
        border-bottom-color: $pengine-grey-dark-dark;
        border-bottom-width: 1px;
        border-bottom-style: solid;
      }
    }
  }

  .header-main {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-flow: column-reverse;

    .header-bar-container .dropdown:not(.is-active) {
      z-index: 31;
    }

    // Let them appear in front of dialogs
    .top-container-in-header {
      background-color: white;
      z-index: 30;
    }

    .menu-button-container {
      float: left;
      padding-top: 22px;
      padding-left: 16px;
      padding-right: 112px; // To take the same space like the back button

      .sidebar-button-icon {
        height: 30px;
        width: 30px;
        margin-top: -6px;
      }
    }

    .top-container-in-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .left-aligned-header-part {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        .back-link {
          display: flex;
          height: fit-content;


          .back-link-content {
            width: $header-back-button-width;
            height: $header-height - 1px;
            padding: 22px;

            &:hover {
              background-color: $pengine-grey-light-light;
            }
          }

          a {
            color: black;
            font-weight: normal;
          }
        }

        .back-button-icon {
          float: left;
          height: 18px;
          width: 18px;
        }

        .back-button-text {
          float: left;
          margin-top: -4px;
          margin-left: 6px;
          font-size: $font-size-header;
          color: $text-color;
        }
      }
    }

    .logo-and-user-container {
      display: flex;
      justify-self: flex-end;
      flex-flow: row nowrap;
    }
  }
}


.header-with-pinned-bar {
  // height: ($header-height + 50px) !important;
  height: auto !important;
}

</style>
<style lang="scss">
@import 'src/assets/styles/constants';

.header {

  .header-tabs-container {
    padding-top: 16px;
    padding-left: 200px;
    width: auto;
    font-weight: initial;

    .header-tabs {
      width: 100%;
      height: 100%;

      .tabs {
        ul {
          border-bottom: none;

          a {
            padding-top: 7px;
            padding-bottom: 2px;
            border-bottom-width: 3px;
            font-size: 1rem;
          }
        }
      }

      .tab-content {
        height: 0px;
        padding: 0 0 0 0;
      }
    }
  }

  .header-bar-container {
    font-size: $font-size-normal;
    font-weight: normal;
    height: 0;

    .navbar-item {
      background-color: transparent;

      &:hover, &:focus {
        background-color: transparent;
      }
    }

    > .dropdown {
      width: 100%;

      > .dropdown-trigger {
        width: calc(100% - #{$header-back-button-width} - #{$header-logo-user-width});
        margin: 0px;
        height: 12px;
        position: absolute;
        left: $header-back-button-width;

        .button {
          width: 100%;
          margin-top: -20px;

          span {
            position: absolute;
            bottom: 0;
          }
        }
      }

      > .dropdown-menu {
        width: 100% !important;
        -webkit-box-shadow: 0px 22px 15px 0px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 22px 15px 0px rgba(0, 0, 0, 0.1);

        &.slide-enter-active, &.slide-leave-active {
          transition-duration: $header-transition-duration;
        }

        .dropdown-content {
          box-shadow: none;

          .header-bar-panel {
            width: 100%;
          }
        }
      }
    }

    .header-bar-pinned {
      position: sticky;
      margin-top: -21px;
    }
  }
}
</style>
