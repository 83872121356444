import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-241c022d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "document-structure-tree" }
const _hoisted_2 = { class: "header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tree = _resolveComponent("Tree")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.$t('textBlocks')), 1)
    ]),
    _createVNode(_component_Tree, {
      ref: "document-structure-tree-container",
      class: "tree",
      onOnClick: _ctx.handleClick,
      node: _ctx.root
    }, null, 8, ["onOnClick", "node"])
  ]))
}