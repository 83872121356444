<template>
  <div class="cover-sheet">
    <button v-if="showDefaultButton" :class=getButtonClass() :title=getHeaderTextOrTitle() @click="open()">
      <i :class=getIconClass()></i>
    </button>

    <!-- Use the modal component, pass everything through the slots -->
    <modal-dialog ref="dialog"
                  :onClose="onClose">
      <template v-slot:header>
        <h1>{{ getHeaderTextOrTitle() }}</h1>
      </template>

      <template v-slot:body>
        <div class="row">
          <div class="column">
            <div class="label"><label for="referenceNo">{{ $t('referenceNo') }}
              <span class="required"> {{ $t('general.required') }}</span></label></div>
            <div class="field"><input id="referenceNo" v-model="applicationDocument.application.referenceNo" @input="validate"
                                      :disabled="isLoading" :maxlength="referenceNoMaxLength" :has-counter="false"
                                      ref="referenceNoInput"></div>
            <div class="vertical-separator-input"/>

            <div class="label"><label for="genericTerm">{{ $t('genericTerm') }}</label></div>
            <div class="field field-generic-term">
              <input id="genericTerm" v-model="applicationDocument.genericTerm.term" @input="validate"
                     :placeholder="$t('genericTermPlaceholder')" :disabled="isLoading || editMode === Mode.CLONE"
                     :maxlength="genericTermMaxLength"
                     :has-counter="false">
            </div>
            <div class="vertical-separator-input-genericTerm"/>
            <div class="label"><label for="genericTermComplementText"></label></div>
            <div class="field field-generic-term">
              <textarea id="genericTermComplementText" v-model="applicationDocument.genericTerm.complementText"
                        :placeholder="$t('genericTermComplementTextPlaceholder')" @input="validate"
                        :disabled="isLoading || editMode === Mode.CLONE"
                        :maxlength="genericTermComplementTextMaxLength"
                        :has-counter="false"/>
            </div>
            <div class="vertical-separator-textarea"/>

            <div class="label"><label for="locale">{{ $t('locale') }}</label></div>
            <div class="field">
              <select id="locale"
                      v-model="applicationDocument.locale"
                      @input="validate"
                      :title="$t('locales.' + applicationDocument.locale)"
                      :disabled="isLoading || editMode !== Mode.CREATE"
                      v-on:change="changeValue($event)">
                <option v-for="(applicationTemplateLanguage, index) in applicationTemplateLanguageList"
                        :key="index"
                        :value="applicationTemplateLanguage">
                  {{ $t('locales.' + applicationTemplateLanguage) }}
                </option>
              </select>
            </div>
            <div class="vertical-separator-input"/>

            <div class="label"><label for="applicationType">{{ $t('applicationType') }}
              <span class="required"> {{ $t('general.required') }}</span></label></div>
            <div class="field">
              <select id="applicationType"
                      v-model="selectedApplicationType"
                      :title="selectedApplicationType"
                      :disabled="isLoading || editMode !== Mode.CREATE || applicationTemplateTypeList.length === 0"
                      v-on:change="changeApplicationTemplateType($event)">
                <option v-for="(applicationTemplateType, index) in applicationTemplateTypeList"
                        :key="index"
                        :value="applicationTemplateType">
                  {{ $t(`applicationTypes.${applicationTemplateType}`) }}
                </option>
              </select>
            </div>
            <div class="vertical-separator-input"/>

            <div class="label"><label for="applicationVariant">{{ $t('applicationVariant') }}
              <span class="required"> {{ $t('general.required') }}</span></label></div>
            <div class="field">
              <select id="applicationVariant"
                      v-model="selectedApplicationVariant"
                      :title="selectedApplicationVariant ? (selectedApplicationVariant.name + ' (' +
                              selectedApplicationVariant.versionNumber + ')') : ''"
                      :disabled="isApplicationVariantDisabled()"
                      v-on:change="changeApplicationTemplateVariant($event)">
                <option v-for="(applicationTemplateVariant, index) in applicationTemplateVariantList"
                        :key="index"
                        :value="applicationTemplateVariant"
                        :title="applicationTemplateVariant.description">
                  {{ applicationTemplateVariant.name }} ({{ applicationTemplateVariant.versionNumber }})
                </option>
              </select>
            </div>
          </div>

          <div class="vertical-separator"></div>

          <div class="column">
            <div class="label"><label for="place">{{ $t('place') }}</label></div>
            <div class="field"><input id="place" v-model="applicationDocument.place" @input="validate"
                                      :disabled="isLoading || editMode === Mode.CLONE" :maxlength="placeMaxLength" :has-counter="false">
            </div>
            <div class="vertical-separator-input"/>

            <div class="label"><label for="date">{{ $t('date') }}</label></div>
            <div class="field">
              <b-datepicker id="date"
                            :disabled="isLoading || editMode === Mode.CLONE"
                            v-model="applicationDocument.date"
                            :show-week-number="false"
                            :locale="$i18n.locale"
                            :date-formatter="dateFormat"
                            icon-pack="exi"
                            icon="exi-calendar"
                            icon-prev="exi-chevron-left"
                            icon-next="exi-chevron-right"
                            size="is-small"
                            position="is-bottom-left"
                            trap-focus editable>
              </b-datepicker>
            </div>
            <div class="vertical-separator-input"/>

            <div class="label"><label for="patentHolder">{{ $t('patentHolder') }}</label></div>
            <div class="field"><input id="patentHolder" v-model="applicationDocument.patentHolder.name" @input="validate"
                                      :disabled="isLoading || editMode === Mode.CLONE" :maxlength="patentHolderNameMaxLength"
                                      :has-counter="false"></div>
            <div class="vertical-separator-input"/>

            <div class="label"><label for="clientSign">{{ $t('clientSign') }}</label></div>
            <div class="field"><input id="clientSign" v-model="applicationDocument.client.sign" @input="validate"
                                      :disabled="isLoading || editMode === Mode.CLONE" :maxlength="clientSignMaxLength"
                                      :has-counter="false"></div>
            <div class="vertical-separator-input"/>

            <div class="label"><label for="patentHolderAddress">{{ $t('patentHolderAddress') }}</label></div>
            <div class="field"><textarea rows="5" id="patentHolderAddress" v-model="applicationDocument.patentHolder.address.name"
                                         @input="validate" :disabled="isLoading || editMode === Mode.CLONE"
                                         :maxlength="patentHolderAddressMaxLength" :has-counter="false"/></div>
            <div class="vertical-separator-textarea"/>

            <div class="label"><label for="isDeletable">{{ $t('isDeletable') }}</label></div>
            <div class="field">
              <b-checkbox id="isDeletable" v-model="applicationDocument.isDeletable"
                          @update:modelValue="validate"
                          :disabled="isLoading || editMode === Mode.CLONE"
                          :true-value="false"
                          :false-value="true"/>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:footer>
        <div class="button-row">
          <div class="button-container">
            <button
              class="column button-action"
              v-on:click.once="createSaveApplication()"
              :key="key"
              :disabled="!valid || !changed || isLoading"
              :title="getCreateSaveButtonTitle()">
              <i v-if="isLoading" class="exi exi-small-spinner-unmasked rotating"/>
              {{ getButtonText() }}
            </button>
          </div>
          <div class="button-container">
            <button class="column button-cancel" @click="dialog.closeModal()">{{ $t('general.cancel') }}</button>
          </div>
        </div>
      </template>
    </modal-dialog>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Ref, toNative, Vue, Watch} from 'vue-facing-decorator';
import ModalDialog, {ModalDialog as ModalDialogClass} from '@/components/common/ModalDialog.vue';
import moment, {Moment} from 'moment';
import {ApplicationDocument, CreateApplicationDocumentEvent, UpdateApplicationDocumentEvent} from '@/api/models/application.model';
import ApplicationModule from '@/store/modules/ApplicationModule';
import {PatentEngineConstraints} from '@/constraints';
import {cloneDeep} from 'lodash';
import {ApplicationTemplate} from '@/api/models/applicationTemplate.model';
import ApplicationTemplateModule from '@/store/modules/ApplicationTemplateModule';
import {routeTo} from '@/util/router.util';
import {Mode} from '@/util/enums';
import {useDefaultErrorHandling} from '@/errorHandling';
import {CloneApplicationDocumentEvent} from '@/api/models/worklist.model';

@Component(
  {
    components: {
      ModalDialog
    }
  })
class CoverSheetDialog extends Vue {
  @Prop({default: Mode.CREATE}) private editMode!: Mode;
  @Prop() private applicationDocumentCopy: ApplicationDocument | undefined;
  @Prop({default: undefined}) private onClose!: () => void;
  @Prop({default: true}) private showDefaultButton!: boolean;
  @Ref('dialog') private dialog!: ModalDialogClass;
  @Ref('referenceNoInput') private referenceNoInput!: HTMLInputElement;

  private applicationDocumentOriginal!: ApplicationDocument;
  private applicationDocument!: ApplicationDocument;

  private referenceNoMaxLength = PatentEngineConstraints.FIELD_LENGTH_NAME_SHORT;
  private genericTermMaxLength = PatentEngineConstraints.FIELD_LENGTH_DESCRIPTION;
  private genericTermComplementTextMaxLength = PatentEngineConstraints.FIELD_LENGTH_DESCRIPTION;
  private clientSignMaxLength = PatentEngineConstraints.FIELD_LENGTH_NAME;
  private placeMaxLength = PatentEngineConstraints.FIELD_LENGTH_NAME_LONG;
  private patentHolderNameMaxLength = PatentEngineConstraints.FIELD_LENGTH_NAME_LONG;
  private patentHolderAddressMaxLength = PatentEngineConstraints.FIELD_LENGTH_ADDRESS;

  private applicationTemplateTypeList: string[] = [];
  private applicationTemplateVariantList: ApplicationTemplate[] = [];
  private applicationTemplateLanguageList: string[] = [];

  private applicationTemplates!: ApplicationTemplate[];

  private valid: boolean | string = false;
  private changed = false;
  private selectedApplicationVariant: ApplicationTemplate | undefined = undefined;
  private selectedApplicationType: string | null = null;
  private key = 0;

  mounted(): void {
    // if the dialog is opened to create a new application document, initialize with empty application document
    if (this.editMode === Mode.CREATE) {
      this.applicationDocument = this.createEmptyApplicationDocument();
      ApplicationTemplateModule.fetchAllAplicationTemplate().catch(useDefaultErrorHandling);
      this.applicationDocumentOriginal = cloneDeep(this.applicationDocument); // Keep a copy to reset the dialog
    }
    if (this.editMode === Mode.CLONE && this.applicationDocumentCopy) {
      this.applicationDocument = this.applicationDocumentCopy;
      this.applicationDocumentOriginal = cloneDeep(this.applicationDocument);
    }
  }

  private moment(date: Date): Moment {
    moment.locale(this.$i18n.locale);
    return moment(date);
  }

  get currentApplicationDocument(): ApplicationDocument | null {
    return ApplicationModule.currentApplicationDocument;
  }

  get Mode() {
    return Mode;
  }

  get getAllApplicatonTemplate(): ApplicationTemplate[] {
    const applicationTemplates = ApplicationTemplateModule.applicationTemplates;
    return applicationTemplates;
  }

  @Watch('currentApplicationDocument', {immediate: true})
  private currentApplicationDocumentChanged(applicationDocument: ApplicationDocument | null) {
    this.applicationDocument = (applicationDocument) ? cloneDeep(applicationDocument) : this.createEmptyApplicationDocument();
    this.applicationDocumentOriginal = cloneDeep(this.applicationDocument); // Keep a copy to reset the dialog
  }

  get isLoading(): boolean {
    return ApplicationModule.isLoading;
  }

  private createEmptyApplicationDocument() {
    return {
      application: {referenceNo: ''},
      client: {sign: ''},
      type: '',
      variant: '',
      genericTerm: {term: '', complementText: ''},
      patentHolder: {name: '', address: {name: ''}},
      date: new Date(),
      creationDate: new Date(),
      isDeletable: true,
      place: '',
      locale: 'de-DE',
      isStructurallySound: false,
      templateTexts: []
    };
  }

  @Watch('getAllApplicatonTemplate')
  private initTempaltes(applicationTemplates: ApplicationTemplate[]) {
    this.applicationTemplates = applicationTemplates;
    this.templateDetails();
  }

  // Using watcher on applicationdocument.date as @input handler on datepicker component seemed unreliable
  @Watch("applicationDocument.date")
  private dateChanged(date: Date) {
    this.validate();
  }

  private templateDetails() {
    const valuesOfTemplates = cloneDeep(this.applicationTemplates);

    const templatesForCurrentLanguage = valuesOfTemplates.filter(template => (template.locale === this.applicationDocument.locale));
    const templatesLanguages = valuesOfTemplates.filter(template => template.locale);

    const listOfApplicationTypes = templatesForCurrentLanguage.map(value => value.applicationType);
    const listOfApplicationLanguages = templatesLanguages.map(value => value.locale);

    this.applicationTemplateTypeList = Array.from(new Set(listOfApplicationTypes).values());
    this.applicationTemplateLanguageList = [...new Set(listOfApplicationLanguages)];

    this.applicationTemplateVariantList =
      templatesForCurrentLanguage.filter(template => (!this.selectedApplicationType
        || this.selectedApplicationType === ''
        || (template.applicationType === this.selectedApplicationType)));
  }

  private changeApplicationTemplateType() {
    this.templateDetails();
    this.validate();
  }

  private changeApplicationTemplateVariant() {
    this.selectedApplicationType = (this.selectedApplicationVariant) ? this.selectedApplicationVariant.applicationType : '';
    this.templateDetails();
    this.validate();
  }

  private changeValue() {
    this.templateDetails();
  }

  private validate(): void {
    this.valid = this.applicationDocument.application.referenceNo && !!this.selectedApplicationVariant;
    if (this.editMode === Mode.CLONE) {
      this.valid = this.valid
        // referenceNo has to differ between original and clone
        && this.applicationDocument.application.referenceNo !== this.applicationDocumentOriginal.application.referenceNo;
    }
    this.hasChanges();
  }

  private hasChanges(): void {
    this.changed = this.applicationDocument.application.referenceNo !== this.applicationDocumentOriginal.application.referenceNo
      || this.applicationDocument.genericTerm.term !== this.applicationDocumentOriginal.genericTerm.term
      || this.applicationDocument.genericTerm.complementText !== this.applicationDocumentOriginal.genericTerm.complementText
      || this.applicationDocument.client.sign !== this.applicationDocumentOriginal.client.sign
      || this.applicationDocument.place !== this.applicationDocumentOriginal.place
      || !this.sameDate(this.applicationDocument.date, this.applicationDocumentOriginal.date)
      || this.applicationDocument.patentHolder.name !== this.applicationDocumentOriginal.patentHolder.name
      || this.applicationDocument.patentHolder.address.name !== this.applicationDocumentOriginal.patentHolder.address.name
      || this.applicationDocument.locale !== this.applicationDocumentOriginal.locale
      || this.applicationDocument.isDeletable !== this.applicationDocumentOriginal.isDeletable;
  }

  private sameDate(dateA: Date | null, dateB: Date | null): boolean {
    if (dateA === null && dateB === null) {
      return true;
    }
    if (dateA === null || dateB === null) {
      return false;
    }
    if (dateA.getFullYear() !== dateB.getFullYear()) {
      return false;
    }
    if (dateA.getMonth() !== dateB.getMonth()) {
      return false;
    }
    return dateA.getDate() === dateB.getDate();

  }

  private getCreateSaveButtonTitle(): string {
    if (!this.valid) {
      return this.$t('general.mandatoryFieldsMustBeFilledIn') as string;
    } else if (!this.changed) {
      return this.$t('general.cannotSaveWithoutChanges') as string;
    } else {
      return '';
    }
  }

  private getIconClass(): string {
    let className = '';
    if (this.editMode === Mode.EDIT) {
      className = 'pen';
    } else if (this.editMode === Mode.CREATE) {
      className = 'plus';
    } else if (this.editMode === Mode.CLONE) {
      className = 'copy-application'
    }
    return 'exi exi-' + className;
  }

  private getButtonClass(): string {
    if (this.editMode == Mode.CLONE) {
      return "icon-button-clone";
    }
    return "icon-button";
  }

  private getHeaderTextOrTitle(): string {
    if (this.editMode === Mode.EDIT) {
      return this.$t('coverSheetEdit') as string;
    } else if (this.editMode === Mode.CREATE) {
      return this.$t('coverSheetCreate') as string;
    } else if (this.editMode === Mode.CLONE) {
      return this.$t('coverSheetClone') as string;
    }
    return "";
  }

  private getButtonText(): string {
    if (this.editMode === Mode.EDIT) {
      return this.$t('general.save') as string;
    } else if (this.editMode === Mode.CREATE) {
      return this.$t('general.create') as string;
    } else if (this.editMode === Mode.CLONE) {
      return this.$t('general.clone') as string;
    }
    return "";
  }

  private dateFormat(date: Date): string {
    return this.moment(date).format('L');
  }

  private createSaveApplication(): void {
    const applicationDocumentEvent: CreateApplicationDocumentEvent = {
      applicationReferenceNo: this.applicationDocument.application.referenceNo,
      clientSign: this.applicationDocument.client.sign,
      patentHolderName: this.applicationDocument.patentHolder.name,
      patentHolderAddress: this.applicationDocument.patentHolder.address.name,
      date: this.applicationDocument.date as Date,
      place: this.applicationDocument.place,
      genericTerm: this.applicationDocument.genericTerm.term,
      genericTermComplementText: this.applicationDocument.genericTerm.complementText,
      locale: this.applicationDocument.locale,
      applicationTemplateGuid: this.selectedApplicationVariant?.guid,
      isDeletable: this.applicationDocument.isDeletable
    }
    if (this.editMode === Mode.EDIT) {
      // Update existing cover sheet
      const updateApplicationDocumentEvent: UpdateApplicationDocumentEvent = {
        ...applicationDocumentEvent,
        guid: this.applicationDocument.guid as string
      };
      ApplicationModule.updateApplicationDocument(updateApplicationDocumentEvent)
        .then(() => {
          this.dialog.closeModal();
        })
        .catch(useDefaultErrorHandling)
        .finally(() => {
          this.key++;
        });
    } else if (this.editMode === Mode.CLONE) {
      const cloneApplicationDocumentEvent: CloneApplicationDocumentEvent = {
        applicationReferenceNo: applicationDocumentEvent.applicationReferenceNo,
        guid: this.applicationDocument.guid as string,
      };
      ApplicationModule.cloneApplicationDocument(cloneApplicationDocumentEvent)
        .then((guid: string) => {
          routeTo('application/' + guid); // Has to be the guid of the newly cloned application
          this.dialog.closeModal();
        })
        .catch(useDefaultErrorHandling)
        .finally(() => {
          this.key++;
        });
    } else {
      // Create new cover sheet (The server will return the guid on success)
      ApplicationModule.createApplicationDocument(applicationDocumentEvent)
        .then((guid: string) => {
          // Display the splash screen and open the new application document in the application editor
          routeTo('application/' + guid);
          this.dialog.closeModal();
        })
        .catch(useDefaultErrorHandling)
        .finally(() => {
          this.key++;
        });
    }
  }

  public open(): void {
    this.applicationDocument = cloneDeep(this.applicationDocumentOriginal); // Reset to last loaded
    if (this.editMode === Mode.EDIT) {
      if (this.applicationDocument.template?.applicationType) {
        this.applicationTemplateTypeList.push(this.applicationDocument.template?.applicationType);
        this.selectedApplicationType = this.applicationDocument.template?.applicationType;
      }
      if (this.applicationDocument.template) {
        this.applicationTemplateVariantList.push(this.applicationDocument.template);
        this.selectedApplicationVariant = this.applicationDocument.template;
      }
      this.applicationTemplateLanguageList.push(this.applicationDocument.locale);
    } else if (this.editMode === Mode.CREATE) {
      this.templateDetails();
      this.selectedApplicationVariant = undefined;
      this.selectedApplicationType = null;
    } else {
      // Enforce working on updated but unrelated value
      if (this.applicationDocumentCopy) {
        this.applicationDocument = cloneDeep(this.applicationDocumentCopy);
        this.applicationDocumentOriginal = cloneDeep(this.applicationDocument);
      }
      if (this.applicationDocument.template?.applicationType) {
        this.applicationTemplateTypeList.push(this.applicationDocument.template?.applicationType);
        this.selectedApplicationType = this.applicationDocument.template?.applicationType;
      }

      this.selectedApplicationVariant = this.applicationDocument.template;
      if (this.selectedApplicationVariant) {
        this.applicationTemplateVariantList.push(this.selectedApplicationVariant);
      }
      this.applicationTemplateLanguageList.push(this.applicationDocument.locale);
    }

    this.validate();
    this.dialog.openModal();
    setTimeout(() => {
      this.referenceNoInput.focus();
    }, 100);
  }

  public close(): void {
    this.dialog.closeModal();
  }

  private isApplicationVariantDisabled(): boolean {
    return this.isLoading || !this.selectedApplicationType || this.editMode !== Mode.CREATE
      || this.applicationTemplateVariantList.length == 0;
  }
}

export default toNative(CoverSheetDialog);
export {CoverSheetDialog};
</script>

<style lang="scss">
.cover-sheet {
  .modal-dialog {
    $dialog-width: 1200px;

    &-dialog {
      width: $dialog-width;
      max-height: 100%;
      overflow-x: auto !important;

      .modal-dialog-body {
        width: $dialog-width;
      }
    }

    &-header {
      width: $dialog-width;

      h1 {
        margin: 0 auto;
      }

      .icon-button {
        margin-top: -12px;
        margin-right: -12px;
        padding: 6px !important;
      }
    }

    &-footer {
      width: $dialog-width;
      padding-top: 12px;
      padding-bottom: 16px;

      .button-row {
        padding-right: 5px;

        button {
          width: 128px;
          height: 30px;
          padding: 4px;
          margin-left: 8px;
          margin-right: 8px;
        }

        .button-container {
          float: right;
        }
      }
    }

    input.is-small {
      height: 30px;
      font-size: 16px !important;
    }

    .icon.is-small {
      height: 30px !important;
      margin: 0px;
    }

    span.is-small select {
      padding-top: 1px !important;
    }

    span.is-small select:hover {
      text-indent: 0px;
    }

  }
}

.document-structure-node-selected .exi-pen:not(:hover) {
  background-color: white !important;
}
</style>

<style scoped lang="scss">
@import 'src/assets/styles/constants';

.cover-sheet {
  $TextAreaHeight: 80px;
  $InputHeight: 30px;
  $SpacingDefault: 30px;
  $SpacingSmall: 10px;

  button {
    float: right;
    margin-bottom: 8px;
    padding-right: 43px; // Optimized for Worklist
  }

  .icon-button-clone {
    padding-right: 0px;
    background-color: transparent;
  }

  .icon-button-clone:hover {
    background-color: transparent;
  }

  .row {
    display: flex;
  }

  .column {
    flex: 50%;
    padding-bottom: 0px;

    textarea {
      height: $TextAreaHeight;
    }

    .vertical-separator-textarea {
      padding-top: $TextAreaHeight + $SpacingDefault; // Optimized for GenericTermComplementText TextArea
    }

    .vertical-separator-input {
      padding-top: $InputHeight + $SpacingDefault;

      &-genericTerm {
        padding-top: $InputHeight + $SpacingSmall;
      }
    }
  }

  .vertical-separator {
    width: 60px;
  }

  .label {
    float: left;
    width: 180px;
    text-align: left;
    font-weight: 200;
    font-size: $font-size-modal;
  }

  .field {
    float: right;
    margin-right: 9px;
    width: 320px;
    display: flex;

    &-generic-term {
      margin-bottom: 7px;
    }

    input, select, textarea, .datepicker {
      font-size: $font-size-modal;
      width: 100%;
    }

    input, select, textarea {
      padding: 4px;
    }

    input:enabled:hover, textarea:enabled:hover, select:enabled:hover {
      padding-left: 3px !important;
    }

    input:enabled:focus, textarea:enabled:focus, select:enabled:focus {
      padding-left: 3px !important;
    }

    input::placeholder, select::placeholder, textarea::placeholder {
      color: $pengine-grey;
    }

    input, select {
      height: $InputHeight;
    }

    .checkbox {
      height: $InputHeight;
    }

    :disabled {
      background-color: $pengine-grey-light;
      color: $pengine-grey-dark;
    }

    :disabled::placeholder {
      color: $pengine-grey-dark-dark;
    }

    textarea {
      resize: none;
    }

    .required {
      font-style: italic;
      font-size: $font-size-required;
    }
  }
}
</style>
