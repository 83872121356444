import {
  COMPASS_DIRECTIONS_FOR_SNAP_CLOUD,
  COMPASS_DIRECTIONS_OF_BOTTOM_SIDE_SNAP_POINTS,
  COMPASS_DIRECTIONS_OF_LEFT_SIDE_SNAP_POINTS,
  COMPASS_DIRECTIONS_OF_RIGHT_SIDE_SNAP_POINTS,
  COMPASS_DIRECTIONS_OF_TOP_SIDE_SNAP_POINTS,
  CompassDirection,
  FigureOrientation,
  FigureSymbolSubType,
  FigureSymbolType,
  MarkerSnapPoint,
  OFFSET,
  ReferenceSignMarker,
  RESOLUTION,
  SelectionConfig,
  SIZES,
  SNAP_CLOUD_FOR_GROUP_SIZE,
  SNAP_CLOUD_RANGE,
  SNAP_CLOUD_SIZE,
  SNAP_POINT_OFFSET,
  SNAP_POINT_OFFSET_NS,
  STYLES,
  SymbolSnapDirection,
  SymbolSnapPoint
} from '../api/models/drawingbase.model';
import * as paper from 'paper';
import * as uuid from 'uuid';

/**
 * Converts the given number centimiters to pixels.
 *
 * @example
 * dpi = 300 px / in
 * 1 inch = 2.54 cm
 * 300 dpi = 300 px / 2.54 cm
 *
 * @see https://github.com/ryanve/res
 * @param cm The number of centimiters to be converted.
 * @returns {number}
 */
const cmToPixels = (cm: number): number => {
  const dpi = RESOLUTION;
  return dpi * cm / 2.54;
}

/**
 * Gets the offset of the canvas.
 * @returns {number}
 */
export const getOffset = (): number => {
  return cmToPixels(OFFSET);
}

/**
 * Gets the corresponding canvas size based on the given orientation.
 * @param orientation The orientation of the figure to be used to determine the canvas size.
 */
export const getCanvasSizeFromOrientation = (orientation: FigureOrientation): { width: number; height: number } => {
  return SIZES.A4[orientation]
}

/**
 * Throws an error, if obj is undefined or null.
 * @param obj
 */
export function required<T>(obj: T | undefined | null): T {
  if (obj === undefined || obj === null) {
    throw new Error("Object is required");
  }
  return obj;
}

export function asGroup(item: paper.Item | undefined): paper.Group | undefined {
  return item && item.className === 'Group' ? item as paper.Group : undefined;
}

export function asGroupRequired(item: paper.Item): paper.Group {
  return required(asGroup(item));
}

export function filterOnlyGroups(items: paper.Item[]): paper.Group[] {
  return items
    .map(it => asGroup(it))
    .filter(it => !!it)
    .map(it => required(it));
}

export function getSymbolType(item: paper.Item | undefined): FigureSymbolType | undefined {
  return asGroup(item)?.data.type;
}

function isType(item: paper.Item, type: FigureSymbolType): boolean {
  return getSymbolType(item) === type;
}

export type ItemPredicate = (item: paper.Item) => boolean;

export function isTypeLine(item: paper.Item): boolean {
  return isType(item, FigureSymbolType.LINE);
}

export function isTypeArrow(item: paper.Item): boolean {
  return isType(item, FigureSymbolType.ARROW);
}

export function isTypeBrace(item: paper.Item): boolean {
  return isType(item, FigureSymbolType.BRACE);
}

export function isTypeCurve(item: paper.Item): boolean {
  return isType(item, FigureSymbolType.CURVE);
}

export function isTypePaletteSymbol(item: paper.Item): boolean {
  return isTypeLine(item) || isTypeArrow(item) || isTypeBrace(item) || isTypeCurve(item);
}

export function isTypeReferenceSignMarker(item: paper.Item): boolean {
  return isType(item, FigureSymbolType.REFERENCE_SIGN_MARKER);
}

export function isTypeHelpLine(item: paper.Item): boolean {
  return isType(item, FigureSymbolType.HELP_LINE);
}

export function isTypeWithUnderlineSupport(item: paper.Item): boolean {
  return isTypeReferenceSignMarker(item);
}

export function getSymbolGuid(item: paper.Item | undefined): string | undefined {
  return asGroup(item)?.name;
}

export function getSymbolGuidRequired(item: paper.Item | undefined): string {
  return required(getSymbolGuid(item));
}

/**
 * Compare two strings using localeCompare() with numeric sorting and base sensitivity.
 * See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare
 */
export function compareStrings(a: string, b: string): number {
  return a.localeCompare(b, undefined, {
    numeric: true,
    sensitivity: 'base'
  });
}

/**
 * Creates a valid guid with uuid v4 format.
 * @private
 */
export function createGuid() {
  return uuid.v4();
}

export function getBraceTip(item: paper.Item) {
  const braceTip = item.children
    .filter(it => it instanceof paper.Group)
    .flatMap(it => it.children)
    .flatMap(it => it.children)
    .find(it => it.data.type === FigureSymbolSubType.BRACE_TIP)

  if (!braceTip) {
    throw new Error("Brace tip not found.");
  }
  return (braceTip as paper.Path).lastSegment;
}

export function isSnapCloud(item: paper.Item) {
  return item.data.type === FigureSymbolSubType.SNAP_CLOUD;
}

export function isSnapPoint(item: paper.Item) {
  return item.data.type === FigureSymbolSubType.SNAP_POINT;
}

export function getSnapCloud(item: paper.Item) {
  const snapCloud = item.children.find(it => isSnapCloud(it));

  if (!snapCloud) {
    const itemName = item.name || 'Unnamed item';
    throw new Error(`Snap cloud not found: The item "${itemName}" does not contain a child with type "snapPointObject".`);
  }
  return snapCloud;
}

export function getSnapPoint(snapCloud: paper.Item, direction: CompassDirection) {
  const snapPoint = snapCloud!.children.find(snapPoint => snapPoint.data.direction === direction);

  if (!snapPoint) {
    throw new Error(`Snap point not found: Could not locate a snap point with the direction '${direction}'.`);
  }
  return snapPoint;
}

export function getSnapDirectionForSegment(line: paper.Path, segment: paper.Segment) {
  if (segment === line.firstSegment) {
    return SymbolSnapDirection.X1;
  } else if (segment === line.lastSegment) {
    return SymbolSnapDirection.X2;
  }
  throw new Error("Invalid segment: The provided segment is neither the first nor the last segment of the line.");
}

export function getMarkerSnapPointByDirection(item: paper.Item,
                                              direction: CompassDirection) {
  if (!isTypeReferenceSignMarker(item)) {
    throw new Error("Invalid item type: The provided item is not a ReferenceSignMarker.");
  }
  return item.data.snapPoints
    .find((it: MarkerSnapPoint) => it.direction === direction);
}

export function getSymbolSnapPointByDirection(item: paper.Item,
                                              direction: SymbolSnapDirection) {
  if (!isTypePaletteSymbol(item)) {
    throw new Error("Invalid item type: The provided item is not a PaletteSymbol.");
  }
  return item.data.snapPoints
    .find((it: SymbolSnapPoint) => it.direction === direction);
}

export function getSubItem(item: paper.Item, ...subTypes: FigureSymbolSubType[]) {
  const subItem = item.children.find((child: paper.Item) => subTypes.includes(child.data.type));

  if (!subItem) {
    throw new Error(`Sub-item not found: None of the children match the specified sub-types: [${subTypes.join(', ')}].`);
  }
  return subItem as paper.Path;
}

export function addTopSnapPoint(item: paper.Item, snapCloud: paper.Group, direction: CompassDirection) {
  const pos = item.bounds.topCenter.add(new paper.Point(0, -SNAP_POINT_OFFSET_NS));
  const currentPoint = new paper.Path.Circle(pos, 4);

  currentPoint.data = {
    type: FigureSymbolSubType.SNAP_POINT,
    direction: direction,
    selectionConfig: {
      neverSelect: true
    } as SelectionConfig
  };
  currentPoint.fillColor = new paper.Color(STYLES.SNAP_POINT.fillColor);

  snapCloud.addChild(currentPoint);
}

export function addBottomSnapPoint(item: paper.Item, snapCloud: paper.Group, direction: CompassDirection) {
  const pos = item.bounds.bottomCenter.add(new paper.Point(0, SNAP_POINT_OFFSET_NS));
  const currentPoint = new paper.Path.Circle(pos, 4);

  currentPoint.data = {
    type: FigureSymbolSubType.SNAP_POINT,
    direction: direction,
    selectionConfig: {
      neverSelect: true
    } as SelectionConfig
  };
  currentPoint.fillColor = new paper.Color(STYLES.SNAP_POINT.fillColor);

  snapCloud.addChild(currentPoint);
}

export function addLeftSnapPoints(item: paper.Item, snapCloud: paper.Group) {
  const pos = item.position;
  const helperLength = SNAP_POINT_OFFSET + STYLES.REFERENCE_SIGN.fontSize / 2;

  for (let i = 1; i < 4; i++) {
    const helper = new paper.Path.Line(pos, pos.add(new paper.Point(0, helperLength)));
    helper.rotate(45 * i, pos);
    helper.fillColor = new paper.Color('red');

    // At 315 Degrees, there seems to be a rounding issue...
    const snapPoint = helper.getLocationAt(helperLength - 0.0001).point;
    const adjustedSnapPoint = snapPoint.subtract(new paper.Point((item.bounds.width - 37) / 2, 0));
    const currentPoint = new paper.Path.Circle(adjustedSnapPoint, 4);

    // Remove the helper line after use
    helper.remove();

    currentPoint.data = {
      type: FigureSymbolSubType.SNAP_POINT,
      direction: COMPASS_DIRECTIONS_FOR_SNAP_CLOUD[i],
      selectionConfig: {
        neverSelect: true
      } as SelectionConfig
    };
    currentPoint.fillColor = new paper.Color(STYLES.SNAP_POINT.fillColor);

    snapCloud.addChild(currentPoint);
  }
}

export function addRightSnapPoints(item: paper.Item, snapCloud: paper.Group) {
  const pos = item.position;
  const helperLength = SNAP_POINT_OFFSET + STYLES.REFERENCE_SIGN.fontSize / 2;

  for (let i = 5; i < 8; i++) {
    const helper = new paper.Path.Line(pos, pos.add(new paper.Point(0, helperLength)));
    helper.rotate(45 * i, pos);
    helper.fillColor = new paper.Color('red');

    // At 315 Degrees, there seems to be a rounding issue...
    const snapPoint = helper.getLocationAt(helperLength - 0.0001).point;
    const adjustedSnapPoint = snapPoint.add(new paper.Point((item.bounds.width - 37) / 2, 0));
    const currentPoint = new paper.Path.Circle(adjustedSnapPoint, 4);

    // Remove the helper line after use
    helper.remove();

    currentPoint.data = {
      type: FigureSymbolSubType.SNAP_POINT,
      direction: COMPASS_DIRECTIONS_FOR_SNAP_CLOUD[i],
      selectionConfig: {
        neverSelect: true
      } as SelectionConfig
    };
    currentPoint.fillColor = new paper.Color(STYLES.SNAP_POINT.fillColor);

    snapCloud.addChild(currentPoint);
  }
}

export function initSnapPoints(item: paper.Item) {
  if (isTypeReferenceSignMarker(item)) {
    const snapCloud = getSnapCloud(item);

    item.data.snapPoints = snapCloud!.children
      .map((it: paper.Item) => {
        return {
          guid: createGuid(),
          coordinateX: it.position.x,
          coordinateY: it.position.y,
          direction: it.data.direction,
          symbolSnapPointGuids: []
        };
      });
  } else if (isTypeLine(item) || isTypeArrow(item) || isTypeCurve(item)) {
    const line = getSubItem(item,
                            FigureSymbolSubType.LINE_PATH,
                            FigureSymbolSubType.ARROW_TAIL,
                            FigureSymbolSubType.CURVE_PATH);

    item.data.snapPoints = [
      {
        guid: createGuid(),
        coordinateX: line.firstSegment.point.x,
        coordinateY: line.firstSegment.point.y,
        direction: SymbolSnapDirection.X1,
        markerSnapPointGuid: null
      },
      {
        guid: createGuid(),
        coordinateX: line.lastSegment.point.x,
        coordinateY: line.lastSegment.point.y,
        direction: SymbolSnapDirection.X2,
        markerSnapPointGuid: null
      }];
  } else if (isTypeBrace(item)) {
    const braceTip = getBraceTip(item);

    item.data.snapPoints = [
      {
        guid: createGuid(),
        coordinateX: braceTip.point.x,
        coordinateY: braceTip.point.y,
        direction: SymbolSnapDirection.MIDPOINT_BRACE,
        markerSnapPointGuid: null
      }];
  } else {
    throw new Error(
      "Unknown item type: The provided item does not match any expected types (ReferenceSignMarker, Line, Arrow, Curve, Brace).");
  }
}

export function updateSnapPoints(item: paper.Item) {
  if (isTypeReferenceSignMarker(item)) {
    const snapCloud = getSnapCloud(item);

    item.data.snapPoints = item.data.snapPoints
      .map((it: MarkerSnapPoint) => {
        const snapPoint = getSnapPoint(snapCloud, it.direction);
        return {
          ...it,
          coordinateX: snapPoint!.position.x,
          coordinateY: snapPoint!.position.y
        };
      });
  } else if (isTypeLine(item) || isTypeArrow(item) || isTypeCurve(item)) {
    const line = getSubItem(item,
                            FigureSymbolSubType.LINE_PATH,
                            FigureSymbolSubType.ARROW_TAIL,
                            FigureSymbolSubType.CURVE_PATH);

    item.data.snapPoints = item.data.snapPoints
      .map((it: SymbolSnapPoint) => ({
        ...it,
        coordinateX: it.direction === SymbolSnapDirection.X1 ? line.firstSegment.point.x : line.lastSegment.point.x,
        coordinateY: it.direction === SymbolSnapDirection.X1 ? line.firstSegment.point.y : line.lastSegment.point.y
      }));
  } else if (isTypeBrace(item)) {
    const braceTip = getBraceTip(item);

    item.data.snapPoints = item.data.snapPoints
      .map((it: SymbolSnapPoint) => ({
        ...it,
        coordinateX: braceTip.point.x,
        coordinateY: braceTip.point.y,
      }))
  } else {
    throw new Error(
      "Unknown item type: The provided item does not match any expected types (ReferenceSignMarker, Line, Arrow, Curve, Brace).");
  }
}

export function connectSnapPoints(markerItem: paper.Item,
                                  markerSnapPointDirection: CompassDirection,
                                  symbolItem: paper.Item,
                                  symbolSnapPointDirection: SymbolSnapDirection) {
  const markerSnapPoint = getMarkerSnapPointByDirection(markerItem, markerSnapPointDirection);
  const symbolSnapPoint = getSymbolSnapPointByDirection(symbolItem, symbolSnapPointDirection);

  symbolSnapPoint.markerSnapPointGuid = markerSnapPoint.guid;

  if (!markerSnapPoint.symbolSnapPointGuids.includes(symbolSnapPoint.guid)) {
    markerSnapPoint.symbolSnapPointGuids.push(symbolSnapPoint.guid);
  }
}

export function detachSnapPoints(markerItem: paper.Item,
                                 markerSnapPointDirection: CompassDirection,
                                 symbolItem: paper.Item,
                                 symbolSnapPointDirection: SymbolSnapDirection) {
  const markerSnapPoint = getMarkerSnapPointByDirection(markerItem, markerSnapPointDirection);
  const symbolSnapPoint = getSymbolSnapPointByDirection(symbolItem, symbolSnapPointDirection);

  if (symbolSnapPoint.markerSnapPointGuid === markerSnapPoint.guid) {
    symbolSnapPoint.markerSnapPointGuid = null;
  }
  if (markerSnapPoint.symbolSnapPointGuids.includes(symbolSnapPoint.guid)) {
    markerSnapPoint.symbolSnapPointGuids = markerSnapPoint.symbolSnapPointGuids
      .filter((it: string) => it !== symbolSnapPoint.guid);
  }
}

export function detachAllSnapPoints(markerItem: paper.Item,
                                    symbolItem: paper.Item) {
  markerItem.data.snapPoints.forEach((markerSnapPoint: MarkerSnapPoint) => {
    symbolItem.data.snapPoints.forEach((symbolSnapPoint: SymbolSnapPoint) => {
      detachSnapPoints(markerItem, markerSnapPoint.direction, symbolItem, symbolSnapPoint.direction);
    });
  });
}

export interface SnapPointCandidate {
  distance: number;
  child: paper.Item;
  snapPoint: paper.Item;
  alignmentPoint: paper.Point;
  alignmentDirection: SymbolSnapDirection;
}

export interface DockLineCandidate {
  distance: number;
  child: paper.Item;
}

export type SnapCandidate = SnapPointCandidate | DockLineCandidate;

export function findSnapCandidates(markerItem: paper.Item,
                                   segment: paper.Segment,
                                   alignmentDirection: SymbolSnapDirection,
                                   child: paper.Item): SnapPointCandidate[] {
  return markerItem.children
    .filter(it => isSnapCloud(it))
    .flatMap(it => it.children)
    .filter(it => isSnapPoint(it))
    .map(it => ({
      distance: it.position.getDistance(segment.point),
      child: child,
      snapPoint: it,
      alignmentPoint: segment.point,
      alignmentDirection: alignmentDirection
    }));
}

export function highlightSnapPoint(layer: paper.Item,
                                   referenceGroup: paper.Item,
                                   snapPoint: paper.Item) {
  snapPoint.strokeWidth = 24;
  snapPoint.strokeColor = new paper.Color(STYLES.SNAP_POINT.fillColor);
}

export function removeHighlighting(snapPoint: paper.Item) {
  snapPoint.strokeWidth = 0;
}

export function toggleSnapCloud(layer: paper.Item, item: paper.Item, allowDocking: boolean) {
  const snapCloud = getSnapCloud(item);

  snapCloud.visible = allowDocking
    && item.selected
    && layer.children.some((child: paper.Item) => isSnapCloudInRange(snapCloud, child));
}

export function toggleNeighboringSnapClouds(layer: paper.Item, item: paper.Item, allowDocking: boolean) {
  layer.children.forEach((child: paper.Item) => {
    if (isTypeReferenceSignMarker(child)) {
      const snapCloud = getSnapCloud(child);

      snapCloud.visible = allowDocking
        && item.selected
        && isSnapCloudInRange(snapCloud, item);
    }
  });
}

function isSnapCloudInRange(snapCloud: paper.Item, otherItem: paper.Item) {
  const snapCloudPoints = [
    snapCloud.bounds.topLeft,
    snapCloud.bounds.topRight,
    snapCloud.bounds.bottomLeft,
    snapCloud.bounds.bottomRight
  ];

  if (isTypeLine(otherItem) || isTypeArrow(otherItem) || isTypeCurve(otherItem)) {
    const line = getSubItem(otherItem,
                            FigureSymbolSubType.LINE_PATH,
                            FigureSymbolSubType.ARROW_TAIL,
                            FigureSymbolSubType.CURVE_PATH);

    const nearestDistance = snapCloudPoints
      .map(point => line.getNearestPoint(point).getDistance(point))
      .reduce((min, distance) => Math.min(min, distance), Infinity);

    return nearestDistance <= SNAP_CLOUD_RANGE;
  } else if (isTypeBrace(otherItem)) {
    const braceTip = getBraceTip(otherItem);

    const nearestDistance = snapCloudPoints
      .map(point => braceTip.point.getDistance(point))
      .reduce((min, distance) => Math.min(min, distance), Infinity);

    return nearestDistance <= SNAP_CLOUD_RANGE;
  }
  return false;
}

export function findAttachedMarker(layer: paper.Item, symbolSnapPoint: SymbolSnapPoint) {
  return layer.children
    .filter((child: paper.Item) => child.data.snapPoints?.some(
      (it2: SymbolSnapPoint) => symbolSnapPoint.markerSnapPointGuid === it2.guid));
}

export function findAttachedSymbols(layer: paper.Item, markerSnapPoint: MarkerSnapPoint) {
  return layer.children
    .filter((child: paper.Item) => child.data.snapPoints?.some(
      (it2: SymbolSnapPoint) => markerSnapPoint.symbolSnapPointGuids.includes(it2.guid)));
}

export function countActiveSnapPointBottom(referenceSignMarker: ReferenceSignMarker) {
  return referenceSignMarker.snapPoints
    .filter(it => COMPASS_DIRECTIONS_OF_BOTTOM_SIDE_SNAP_POINTS.includes(it.direction))
    .filter(it => it.symbolSnapPointGuids?.length)
    .length;
}

export function countActiveSnapPointTop(referenceSignMarker: ReferenceSignMarker) {
  return referenceSignMarker.snapPoints
    .filter(it => COMPASS_DIRECTIONS_OF_TOP_SIDE_SNAP_POINTS.includes(it.direction))
    .filter(it => it.symbolSnapPointGuids?.length)
    .length;
}

export function countActiveSnapPointRight(referenceSignMarker: ReferenceSignMarker) {
  return referenceSignMarker.snapPoints
    .filter(it => COMPASS_DIRECTIONS_OF_RIGHT_SIDE_SNAP_POINTS.includes(it.direction))
    .filter(it => it.symbolSnapPointGuids?.length)
    .length;
}

export function countActiveSnapPointLeft(referenceSignMarker: ReferenceSignMarker) {
  return referenceSignMarker.snapPoints
    .filter(it => COMPASS_DIRECTIONS_OF_LEFT_SIDE_SNAP_POINTS.includes(it.direction))
    .filter(it => it.symbolSnapPointGuids?.length)
    .length;
}

export function hasActiveSnapPoint(referenceSignMarker: ReferenceSignMarker) {
  return referenceSignMarker.snapPoints
    .some(it => it.symbolSnapPointGuids?.length);
}

export function isActiveMarkerSnapPoint(item: paper.Item, direction: CompassDirection) {
  return item.data.snapPoints
    .find((it: MarkerSnapPoint) => it.direction === direction)
    .symbolSnapPointGuids.length > 0;
}

export function isActiveSymbolSnapPoint(item: paper.Item | undefined, segment: paper.Segment | undefined) {
  if (!item || !segment) {
    return false;
  }
  if (isTypeLine(item) || isTypeArrow(item) || isTypeCurve(item)) {
    const line = getSubItem(item,
                            FigureSymbolSubType.LINE_PATH,
                            FigureSymbolSubType.ARROW_TAIL,
                            FigureSymbolSubType.CURVE_PATH);
    const direction = getSnapDirectionForSegment(line, segment);

    return item.data.snapPoints
      .find((it: SymbolSnapPoint) => it.direction === direction)
      .markerSnapPointGuid !== null;
  }
  return false;
}

export function isSnapPointRecalculationNeeded(referenceSignMarker: ReferenceSignMarker) {
  const isExpansionNeeded = referenceSignMarker.referenceSigns.length > 1
    && referenceSignMarker.snapPoints.length === SNAP_CLOUD_SIZE;

  const isReductionNeeded = referenceSignMarker.referenceSigns.length === 1
    && referenceSignMarker.snapPoints.length === SNAP_CLOUD_FOR_GROUP_SIZE;

  return isExpansionNeeded || isReductionNeeded;
}

export function transFormSnapPointsForGrownSnapCloud(snapPoint: MarkerSnapPoint,
                                                     snapPointsOld: MarkerSnapPoint[],
                                                     item: paper.Item) {
  const directionMapping: Partial<Record<CompassDirection, CompassDirection>> = {
    [CompassDirection.NNW]: CompassDirection.N,
    [CompassDirection.NNE]: CompassDirection.N,
    [CompassDirection.SSW]: CompassDirection.S,
    [CompassDirection.SSE]: CompassDirection.S
  };
  const otherDirectionMapping: Partial<Record<CompassDirection, CompassDirection>> = {
    [CompassDirection.NNW]: CompassDirection.NNE,
    [CompassDirection.NNE]: CompassDirection.NNW,
    [CompassDirection.SSW]: CompassDirection.SSE,
    [CompassDirection.SSE]: CompassDirection.SSW
  };
  const oldDirection = directionMapping[snapPoint.direction];

  if (!oldDirection) {
    snapPoint.symbolSnapPointGuids = snapPointsOld
      .filter((it: MarkerSnapPoint) => it.direction === snapPoint.direction)
      .flatMap(it => it.symbolSnapPointGuids);
  } else {
    // If there is a mapped old direction, calculate distances to determine which snap point should provide
    // the symbolSnapPointGuids based on proximity to snapPointOld.
    const position = new paper.Point(snapPoint.coordinateX, snapPoint.coordinateY);
    const snapPointOld = snapPointsOld.find((it: MarkerSnapPoint) => it.direction === oldDirection);
    const snapPointOldPosition = new paper.Point(snapPointOld!.coordinateX, snapPointOld!.coordinateY);

    const otherDirection = otherDirectionMapping[snapPoint.direction];
    const other = item.data.snapPoints.find((it: MarkerSnapPoint) => it.direction === otherDirection);
    const otherPosition = new paper.Point(other.coordinateX, other.coordinateY);

    snapPoint.symbolSnapPointGuids =
      position.getDistance(snapPointOldPosition) <= otherPosition.getDistance(snapPointOldPosition)
        ? snapPointOld!.symbolSnapPointGuids
        : [];
  }
}

export function transFormSnapPointsForShrunkSnapCloud(snapPoint: MarkerSnapPoint,
                                                      snapPointsOld: MarkerSnapPoint[]) {
  if (snapPoint.direction === CompassDirection.N) {
    snapPoint.symbolSnapPointGuids = snapPointsOld
      .filter((it: MarkerSnapPoint) => [CompassDirection.NNW, CompassDirection.NNE].includes(it.direction))
      .flatMap(it => it.symbolSnapPointGuids);
  } else if (snapPoint.direction === CompassDirection.S) {
    snapPoint.symbolSnapPointGuids = snapPointsOld
      .filter((it: MarkerSnapPoint) => [CompassDirection.SSW, CompassDirection.SSE].includes(it.direction))
      .flatMap(it => it.symbolSnapPointGuids);
  } else {
    snapPoint.symbolSnapPointGuids = snapPointsOld
      .filter((it: MarkerSnapPoint) => it.direction === snapPoint.direction)
      .flatMap(it => it.symbolSnapPointGuids);
  }
}

export function alignSymbolsWithSnapPoint(item: paper.Item, snapPoint: MarkerSnapPoint) {
  const symbols = findAttachedSymbols(item.parent, snapPoint);

  if (!symbols) {
    return [];
  }
  return symbols
    .map(symbol => {
      symbol.data.snapPoints
        .filter((it: SymbolSnapPoint) => snapPoint.symbolSnapPointGuids.includes(it.guid))
        .forEach((it: SymbolSnapPoint) => {
          it.markerSnapPointGuid = snapPoint.guid;
          it.coordinateX = snapPoint.coordinateX;
          it.coordinateY = snapPoint.coordinateY;

          if (isTypeLine(symbol) || isTypeArrow(symbol) || isTypeCurve(symbol)) {
            const snapPointPosition = new paper.Point(snapPoint.coordinateX, snapPoint.coordinateY);
            const line = getSubItem(symbol,
                                    FigureSymbolSubType.LINE_PATH,
                                    FigureSymbolSubType.ARROW_TAIL,
                                    FigureSymbolSubType.CURVE_PATH);
            const segment = it.direction === SymbolSnapDirection.X1 ? line.firstSegment : line.lastSegment;

            segment.point = snapPointPosition;
          } else if (isTypeBrace(symbol)) {
            const snapPointPosition = new paper.Point(snapPoint.coordinateX, snapPoint.coordinateY);
            const braceTip = getBraceTip(symbol);
            const offset = snapPointPosition.subtract(braceTip.point);

            symbol.position = symbol.position.add(offset);
          }
        });
      return symbol;
    });
}

export function clipItemToHeight(item: paper.Item, height: number): paper.Group {
  // Create a new group to hold both the item and the mask
  const group = new paper.Group();

  // Add the passed-in item (e.g., a PointText) to this new group
  group.addChild(item);

  // Clone the item's bounds to manipulate width/height
  const itemBounds = item.bounds.clone();
  const center = itemBounds.center; // midpoint of the item

  // Force the height, preserve the item’s width
  itemBounds.height = height;
  // Re-center vertically
  itemBounds.center = center;

  // Create the mask rectangle
  const maskRect = new paper.Path.Rectangle(
    {
      rectangle: itemBounds,
      fillColor: null, // Transparent so we don't see the mask
      data: {
        // For example: neverSelect
        selectionConfig: {
          neverSelect: true
        } as SelectionConfig
      }
    });

  // Insert the mask as the first child so Paper.js uses it for clipping
  group.insertChild(0, maskRect);
  group.clipped = false;

  // Optionally, copy the item’s data to the group
  group.data = item.data;

  return group;
}