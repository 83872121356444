import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-78f672c4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "exi-lg exi-large-spinner-unmasked rotating" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_drop = _resolveComponent("drop")!
  const _directive_active_element = _resolveDirective("active-element")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "canvas-container",
    style: _normalizeStyle({width: _ctx.clientWidth + 'px', height: _ctx.clientHeight + 'px'})
  }, [
    _createVNode(_component_drop, {
      ref: "drop",
      onDrop: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onDrop($event))),
      class: "canvas-drop"
    }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("i", _hoisted_1, null, 512), [
          [_vShow, _ctx.isLoading]
        ]),
        _withDirectives(_createElementVNode("canvas", {
          id: "figureEditorCanvas",
          ref: "figureEditorCanvas",
          onWheel: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (this.onMouseWheel && this.onMouseWheel(...args))),
          onContextmenu: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (this.onContextMenu && this.onContextMenu(...args))),
          onMouseenter: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (this.onCanvasMouseEnter && this.onCanvasMouseEnter(...args))),
          onMouseleave: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (this.onCanvasMouseLeave && this.onCanvasMouseLeave(...args)))
        }, null, 544), [
          [_vShow, !_ctx.isLoading && _ctx.applicationFigure !== null]
        ]),
        _withDirectives(_createElementVNode("span", null, _toDisplayString(_ctx.$t('canvas.noFigureMessage')), 513), [
          [_vShow, !_ctx.isLoading && _ctx.applicationFigure === null]
        ])
      ]),
      _: 1
    }, 512)
  ], 4)), [
    [_directive_active_element, _ctx.onActiveElementChange]
  ])
}