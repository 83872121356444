export enum StemFormOrigin {
  /**
   * Indicates that a StemForm has been created by a User
   */
  GENERATED = 'GENERATED',
  /**
   * Indicates that a StemForm has been automatically generated
   */
  MANUAL = 'MANUAL'
}

export interface StemForm {
  referenceSignGuid: string | null;
  guid: string | null;
  stem: string;
  stemFormOrigin: StemFormOrigin;
}

export interface CreateStemFormDto {
  referenceSignGuid: string;
  guid: string;
  stem: string;
}