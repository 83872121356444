<template>
  <span class="reference-sign">

    <!-- Use the modal component, pass in the prop -->
    <modal-dialog ref="reference-sign-dialog" :showHeader="true" :showFooter="false" :width="contextWidth" :margin="contextMargin">
      <template v-slot:body>
        <div class="stem-form-header">
          <label class="label" :title="$t('searchStemFormTitle')">{{ $t('searchStemForm') }}</label>
          <button class="icon-word-stem-refresh icon-button"
                  :title="$t('searchStemRefresh')"
                  @click="refreshSearchStems">
            <i :class="isRefreshing ? 'exi exi-small-spinner-unmasked rotating' : 'exi exi-refresh'"/>
          </button>
        </div>
          <b-field class="stem-forms" :title="$t('searchStemFormTitle')">
            <b-taginput size="is-small"
                        :maxlength="this.maxTagLength"
                        ellipsis
                        icon="label"
                        :before-adding="isNoDuplicateStemFormTag"
                        :createTag="onStemFormCreated"
                        @add="onStemFormAdded"
                        @remove="onStemFormRemovedByKey"
                        v-model="this.stemForms"
                        :closable="!isLastStemForm()"
                        :remove-on-keys="bindRemoveKeys()"
                        :readonly="this.isRefreshing"
                        :placeholder="$t('stemForm')"
                        ref="searchStemFormInput">
               <template #selected>
                    <b-tag
                      v-for="(item, index) in this.stemForms"
                      ellipsis
                      :key="index"
                      :title="item.stem"
                      :type="isGeneratedStemForm(item) ? 'tag-style-generated' : 'tag-style-manual'"
                      :closable="!isLastStemForm()"
                      closeType="stemForms"
                      @close="onStemFormRemovedByTagClose(item)"
                    >
                        {{ item.stem }}
                    </b-tag>
               </template>
            </b-taginput>
          </b-field>
          <label class="label" :title="$t('excludeStemFormMatchesTitle')">{{ $t('excludeStemFormMatches') }}</label>
          <b-field class="exclude-stem-form-matches" :title="$t('excludeStemFormMatchesTitle')">
            <b-taginput size="is-small"
                        :maxlength="this.maxTagLength"
                        ellipsis
                        icon="label"
                        :before-adding="isNoDuplicateExcludedTag"
                        :create-tag="createExcludedTag"
                        @add="onExcludeStemFormMatchAdded"
                        @remove="onExcludeStemFormMatchRemovedByKey"
                        v-model="this.excludeStemFormMatches"
                        :readonly="this.isRefreshing"
                        :placeholder="excludeStemFormMatches.length === 0 ? $t('excludeStemFormMatchesTitle') : $t('excludeStemFormMatchesPlaceholder')">
              <template #selected>
                <b-tag
                  v-for="(item, index) in this.excludeStemFormMatches"
                  ellipsis
                  :key="index"
                  :title="item.stem"
                  closable
                  closeType="excludeStemFormMatches"
                  @close="onExcludeStemFormMatchRemovedByTagClose(item)"
                >
                    {{ item.stem }}
                </b-tag>
              </template>
            </b-taginput>
          </b-field>
          <label class="label" :title="$t('stemFormMatchesTitle')">{{ $t('stemFormMatches') }}</label>
          <b-field class="stem-form-matches" :title="$t('stemFormMatchesTitle')">
            <b-taginput size="is-small"
                        readonly
                        ellipsis
                        :modelValue="stemFormMatches"
                        :placeholder="stemFormMatches.length === 0 ? $t('stemFormMatchesTitle') : ''">
                <template #selected>
                    <b-tag
                      v-for="(item, index) in stemFormMatches"
                      ellipsis
                      :ref="index"
                      :key="item.matchWord"
                      :type="item.collisionWithOtherReferenceSign ? 'tag-style-warn':''"
                      :title="item.matchWord"
                      closable
                      @close="onTagExcluded(index)"
                      closeType="stemFormMatches"
                      :tabindex="index">
                        {{ item.matchWord }}
                    </b-tag>
                </template>
            </b-taginput>
          </b-field>
      </template>
    </modal-dialog>

  </span>
</template>

<script lang="ts">
import {Component, Ref, toNative, Vue} from 'vue-facing-decorator';
import ModalDialog, {ModalDialog as ModalDialogClass} from '@/components/common/ModalDialog.vue';
import {ReferenceSign, StemFormsGeneratedViewModel} from '@/api/models/referenceSign.model';
import ReferenceSignModule from '@/store/modules/ReferenceSignModule';
import {useDefaultErrorHandling} from '@/errorHandling';
import {StemForm, StemFormOrigin} from '@/api/models/stemForm.model';
import {StemFormMatch} from '@/api/models/stemFormMatch.model';
import {ExcludedStemForm} from '@/api/models/excludedStemForm.model';
import {v4 as uuidv4} from 'uuid';

@Component(
  {
    components: {
      ModalDialog
    }
  })
class ReferenceSignDialog extends Vue {
  @Ref('reference-sign-dialog') private dialog!: ModalDialogClass;
  @Ref('searchStemFormInput') private searchStemFormInput!: HTMLInputElement;

  contextWidth: number | undefined = 300;
  contextMargin: string | undefined = 'auto auto auto auto';

  referenceSign: ReferenceSign | null = null;
  stemForms: StemForm[] = [];
  stemFormMatches: StemFormMatch[] = [];
  excludeStemFormMatches: ExcludedStemForm[] = [];
  isRefreshing = false;

  maxTagLength = 50;

  open(referenceSign: ReferenceSign): void {
    this.referenceSign = referenceSign;
    // Store copies or empty lists
    this.stemForms = referenceSign.stemForms ? [...referenceSign.stemForms] : [];
    this.excludeStemFormMatches = referenceSign.excludeStemFormMatches ? [...referenceSign.excludeStemFormMatches] : [];
    this.stemFormMatches = referenceSign.stemFormMatches ? [...referenceSign.stemFormMatches] : [];

    this.dialog.openModal();
    setTimeout(() => {
      this.searchStemFormInput.focus();
    }, 100);
  }

  private onStemFormAdded(value: string) {
    const stem = this.stemForms.find(stemForm => stemForm.stem === value.toLowerCase())!;
    this.isRefreshing = true;
    ReferenceSignModule.createStemForm({
      referenceSignGuid: this.referenceSign!.guid!,
      guid: stem.guid!,
      stem: stem.stem})
    .catch(reason => {
        this.stemForms = this.stemForms.filter(value1 => value1.guid === stem.guid);
        throw reason;
    }).catch(useDefaultErrorHandling)
      .finally(() => this.isRefreshing = false);
  }

  private onStemFormRemovedByKey(stemForm: StemForm) {
    this.isRefreshing = true;
    ReferenceSignModule.deleteStemForm(stemForm.guid!)
      .catch(reason => {
        this.stemForms = [...this.stemForms, stemForm]
        throw reason;
      }).catch(useDefaultErrorHandling)
      .finally(() => this.isRefreshing = false);
  }

  private onStemFormRemovedByTagClose(stemForm: StemForm) {
      this.stemForms = this.stemForms.filter(value => value.guid !== stemForm.guid);
      this.isRefreshing = true;
      ReferenceSignModule.deleteStemForm(stemForm.guid!)
        .catch(reason => {
          this.stemForms.push(stemForm);
          throw reason;
        }).catch(useDefaultErrorHandling)
        .finally(() => this.isRefreshing = false);
  }

  private onStemFormCreated(tag: string): StemForm {
    return {
      referenceSignGuid: this.referenceSign!.guid!,
      guid: uuidv4(),
      stem: tag.toLowerCase(),
      stemFormOrigin: StemFormOrigin.MANUAL
    }
  }


  private onExcludeStemFormMatchAdded(value: string) {
    const excludedStem = this.excludeStemFormMatches.find(excludedStemForm => excludedStemForm.stem === value)!;
    this.isRefreshing = true;
    ReferenceSignModule.createExcludedStemForm({
                                         referenceSignGuid: this.referenceSign!.guid!,
                                         guid: excludedStem.guid!,
                                         excludedStem: excludedStem.stem})
      .catch(reason => {
        this.excludeStemFormMatches = this.excludeStemFormMatches.filter(value1 => value1.guid === excludedStem.guid);
        throw reason;
      }).catch(useDefaultErrorHandling)
      .finally(() => this.isRefreshing = false);
  }

  private onExcludeStemFormMatchRemovedByKey(excludedStemForm: ExcludedStemForm) {
    this.isRefreshing = true;
    ReferenceSignModule.deleteExcludedStemForm(excludedStemForm.guid!)
      .catch(reason => {
        this.excludeStemFormMatches = [...this.excludeStemFormMatches, excludedStemForm]
        throw reason;
      }).catch(useDefaultErrorHandling)
      .finally(() => this.isRefreshing = false);
  }

  private onExcludeStemFormMatchRemovedByTagClose(excludedStemForm: ExcludedStemForm) {
    this.excludeStemFormMatches = this.excludeStemFormMatches.filter(value => value.guid !== excludedStemForm.guid);
    this.isRefreshing = true;
    ReferenceSignModule.deleteExcludedStemForm(excludedStemForm.guid!)
      .catch(reason => {
        this.excludeStemFormMatches.push(excludedStemForm);
        throw reason;
      }).catch(useDefaultErrorHandling).finally(() => this.isRefreshing = false);
  }

  setWidth(width: number | undefined): void {
    this.contextWidth = width;
  }

  setMargin(margin: string | undefined): void {
    this.contextMargin = margin;
  }

  private refreshSearchStems(): void {
    if (this.referenceSign?.guid) {
      this.isRefreshing = true;
      ReferenceSignModule.refreshStemForms(this.referenceSign.guid)
        .then((stemFormsGeneratedViewModel: StemFormsGeneratedViewModel) => {
          this.stemForms = [...stemFormsGeneratedViewModel.stemForms]
        })
        .catch(useDefaultErrorHandling)
        .finally(() => this.isRefreshing = false);
    }
  }

  private bindRemoveKeys(): string[] {
    if (this.isLastStemForm()) {
      return [];
    } else {
      return ['Backspace'];
    }
  }

  private isLastStemForm(): boolean {
    return this.stemForms.length <= 1;
  }

  private isNoDuplicateStemFormTag(tag: string): boolean {
    const lowercaseStem = tag.toLocaleLowerCase();
    const noDuplicates = !this.stemForms.some(stemForm => stemForm.stem === lowercaseStem);
    return noDuplicates;
  }

  private isNoDuplicateExcludedTag(tag: string): boolean {
    const lowercaseStem = tag.toLocaleLowerCase();
    return !this.excludeStemFormMatches.some(exclusion => exclusion.stem.toLocaleLowerCase() === lowercaseStem);
  }

  private createExcludedTag(tag: string): ExcludedStemForm {
    return {
      referenceSignGuid: this.referenceSign!.guid!,
      guid: uuidv4(),
      stem: tag,
    }
  }

  private isGeneratedStemForm(item: StemForm) {
    return item.stemFormOrigin === StemFormOrigin.GENERATED;
  }

  private onTagExcluded(index: number): void {
    const elementToExclude = this.stemFormMatches[index];

    const wordAsIsIsNotYetExcluded = this.excludeStemFormMatches
      .map(value => value.stem)
      .indexOf(elementToExclude.matchWord) < 0;
    const lowercaseWordIsNotYetExcluded = !this.excludeStemFormMatches.some(exclusion =>
                                                    exclusion.stem.toLocaleLowerCase() === elementToExclude.matchWord.toLocaleLowerCase());

    const wordIsNotExcluded = wordAsIsIsNotYetExcluded && lowercaseWordIsNotYetExcluded;
    if (wordIsNotExcluded) {
      this.isRefreshing = true;
      const excludedStemForm: ExcludedStemForm = {
        referenceSignGuid: this.referenceSign!.guid!,
        guid: uuidv4(),
        stem: elementToExclude.matchWord,
      }
      this.excludeStemFormMatches = [...this.excludeStemFormMatches, excludedStemForm];
      const dto = {
        guid: elementToExclude.guid,
        excludedStemFormGuid: excludedStemForm.guid
      }
      ReferenceSignModule.excludeStemFormMatch(dto)
        .catch(reason => {
          this.excludeStemFormMatches = this.excludeStemFormMatches.filter(value => value.guid !== excludedStemForm.guid)
          throw reason;
      }).catch(useDefaultErrorHandling)
        .finally(() => this.isRefreshing = false);
    }
  }
}

export default toNative(ReferenceSignDialog);
export {ReferenceSignDialog};
</script>

<style lang="scss">
@import 'src/assets/styles/colors';
@import 'src/assets/styles/constants';

@mixin editable-taginput-styles {

  ::placeholder {
    color: $pengine-grey-dark;
  }

  .taginput-container {
    .tag:not(body).tag-style-manual {

      background-color: $pengine-blue-dark !important;
      color: white;

      a {
        &:before, &:after {
          background-color: white;
        }
      }
    }

    .autocomplete {
      padding-left: 0;

      .control {
        input {
          padding-left: 24px !important;
        }

        .icon {
          width: 24px;
          height: 24px;
          font-size: 22px;
        }
      }
    }
  }
}


.tag {
  //Width of the Tag itself
  //Adjustment in terms of tag size need to be made here. Inside the ellipsis-class will cause distortion
  max-width: calc(100% - 6px);


  .has-ellipsis {
    //Has to be set to maximum since the default value is 10em
    max-width: 100%;
  }
}


.reference-sign {
  .modal-dialog {
    &-dialog {
      min-width: 400px;
      max-height: 400px;

      .modal-dialog-header {
        position: absolute;
        right: 0px;
        width: inherit;
        flex-flow: row-reverse;
      }

      .modal-dialog-body {
        padding-top: 20px;
        padding-bottom: 20px;

        .label {
          font: $text-font;
          font-size: $font-size-label;
          font-weight: 400;
          padding-left: 0px;
          padding-bottom: 0px;
          margin-bottom: 0px;
          width: auto;
        }

        .icon-word-stem-refresh {
          margin-top: 1px;
        }

        .exi { // eXXcellent Icons
          display: inline-block;
          // padding-top: 2px;
          margin-bottom: -3px;
        }

        .field {
          padding-right: 0px;
        }

        .taginput-container {
          min-height: 62px;
          align-items: normal !important;
        }

        .exclude-stem-form-matches {
          @include editable-taginput-styles;
        }

        .stem-form-matches {
          .taginput-container {
            cursor: auto;

            input {
              cursor: auto;
            }

            input {
              padding-left: 7px !important;
            }
            .tag:not(body).tag-style-warn {
              background-color: $pengine-warn-yellow !important;
            }
          }
        }

        .stem-forms {
          .taginput {
            .counter {
              overflow: visible;
              height: 0px;
            }
          }
          @include editable-taginput-styles;
        }
      }
    }
  }
}
</style>

<style scoped lang="scss">

.label {
  float: left;
  padding-left: 8px;
  padding-bottom: 4px;
  width: 190px;
  text-align: left;
}

.field {
  float: right;
  padding-right: 8px;
}

.cancel {
  float: right;
  margin-right: 8px;
}

.ok {
  float: left;
  margin-left: 8px;
}

.reference-button {
  background-color: transparent !important;
  transition: none;
  border: none;
  padding-top: 0px;
  padding-right: 10px;
  padding-left: 10px;

  i {
    height: 20px;
    width: 20px;
  }
}
</style>
